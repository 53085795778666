<template>
  <div class="hotel-detail">
    <p class="crumbs">
      <a href="http://huangyao.cn/index.html">首页</a> >
      <span @click="goback">酒店预订</span> >
      <span>{{ hotelData.hotelName }}</span>
    </p>
    <div class="hotel-info">
      <div class="hotel-title">
        <span class="hotel-name">{{ hotelData.hotelName }}</span>
        <div class="hotel-tags">
          <el-tag type="warning" size="mini" style="margin-right: 5px" v-for="item in hotelData.hotelTypeNames" :key="item">{{item}}</el-tag>
          <el-tag type="warning" size="mini" style="margin-right: 5px" v-for="item in hotelData.hotelFeatureThemeNames" :key="item">{{item}}</el-tag>
        </div>
      </div>
      <p>{{hotelData.hotelBrief}}</p>
      <!-- <p>
        <i class="el-icon-collection"></i
        >半岛酒店坐落在广西贺州市黄姚古镇景区内。秉承自然、时尚、舒适的理念，酒店设计为明清庭院式青砖黛瓦建筑布局，风格古色古香，质朴大方。典雅而不失朴素的装修风格，尽可能向各位宾客提供“家”的感觉，是各界人士休闲度假、探胜仿古，单位团体组织召开会议、开设论坛、文化交流的最佳选择。
        酒店设备设施配套适用、合理，通讯快捷，服务功能齐全，拥有豪华楼中楼复式房，标准单、豪华双人房共十四间，自助厨房和餐厅、会议室等为阁下提供尽善尽美的服务。
      </p> -->
      <Spread :mes2="mes2"></Spread>
      <div class="hotel-img">
        <div class="left">
          <div class="big-img">
            <!-- <img :src="carouselImg[0]" alt="" /> -->
            <el-image
              :src="carouselImg[0]" 
              :preview-src-list="carouselImg">
            </el-image>
            <span><i class="el-icon-picture"></i>共有{{carouselImg.length}}张图片</span>
          </div>
          <div class="small-img">
            <template v-for="(item,index) in carouselImg">
              <el-image
                v-if="index<=3"
                :key="index"
                :src="item" 
                :preview-src-list="carouselImg">
              </el-image>
            </template>
            
            <!-- <img :src="carouselImg[0]" alt="" />
            <img :src="carouselImg[1]" alt="" />
            <img :src="carouselImg[2]" alt="" /> -->
            <!-- <img
              src="http://bibi-sbs.oss-cn-shanghai.aliyuncs.com/2021-11-24/1637752110014.jpg?Expires=2583832105&OSSAccessKeyId=LTAI4GJxALzftKu6WSo8n4oP&Signature=nMmH5LbAMuhwqcCeJ3oj2paVBNY%3D"
              alt=""
            /> -->
          </div>
        </div>
        <div class="right">
          <p><i class="el-icon-location"></i>{{ hotelData.hotelAddress }}</p>
          <p><i class="el-icon-phone"></i>酒店电话：{{hotelData.hotelTelephone}}</p>
          <p><i class="el-icon-time"></i>开业时间：{{hotelData.openDate}}</p>
          <p class="hotel-services-type">
            <!-- <span v-for="(item,index) in hotelData.hotelFacilityNames" :key="index">{{item}}</span> -->
            <!-- <span>会议室</span>
            <span>叫车服务</span>
            <span>房间消毒</span>
            <span>叫醒服务</span>
            <span>行李奇存</span>
            <span>前台保险柜</span>
            <span>安全消防系统</span>
            <span>24小时热水</span> -->
          </p>
          <div id="hotel-map"></div>
        </div>
      </div>
    </div>
    <div class="main-warp">
      <section class="main-content">
        <ul class="tab-sort" :class="{ isfiexd: isfiexd }">
          <li
            class="sort-item"
            :class="{ active: selectId == item.id }"
            v-for="item in tabData"
            :key="item.id"
            @click="goNode(item.id)"
          >
            {{ item.title }}
          </li>
        </ul>
      </section>
      <div class="unnecessary"></div>
      <div class="search-time" id="yd">
        <el-date-picker
          v-model="time"
          type="daterange"
          :range-separator="range"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="changedeta"
          value-format="timestamp"
          :picker-options="expireTimeOption"
        >
        </el-date-picker>
        <el-button @click="getRoomData($route.query.hotelId)">搜索</el-button>
      </div>
      <div class="room-info">
        <ul v-if="isShow">
          <li
            class="room-type"
            v-for="item in roomData"
            :key="item.hotelRoomTypeId"
          >
            <div class="room-item">
              <img :src="imgUrl + item.roomImage" alt="" />
              <div>
                <span>{{ item.roomTypeName }}</span>
                <!-- <p>{{ item.roomFacility }}</p> -->
                <el-popover
                  placement="bottom"
                  title="房间设施"
                  width="500"
                  trigger="click"
                  :content="item.roomFacility">
                  <el-button slot="reference" type="text" style="display:block;color:#e41f19;">房间设施<i class="el-icon-d-arrow-right"></i></el-button>
                </el-popover>
              </div>
            </div>
            <div class="room-item">
              <span>{{item.isCancel=='1'?'可取消':'不可取消'}}</span>
              <span>{{ item.roomFloor }}</span>
              <span>{{ item.bedInfo }}</span>
              <span>{{item.isBreakfast=='1'?'含早餐':'不含早餐'}}</span>
            </div>
            <div class="room-item">
              <span>￥{{ item.roomPrice }}</span>
              <el-button @click="book(item.hotelRoomTypeId)"
                >预订
                <div class="code">
                  <img src="../assets/code/hotel-code.png" alt="" />
                  <p>扫码预订</p>
                </div></el-button
              >
            </div>
          </li>
        </ul>
      </div>
      <div class="hotel-introduce" id="js">
        <span class="introduce-title">酒店介绍</span>
        <div class="introduce-content" v-if="hotelData.hotelId != 1" v-html="hotelData.hotelDetail">
          <!-- <p>黄姚半岛酒店坐落在广西贺州市黄姚古镇景区内，出游便利。</p>
          <p>
            秉承自然、时尚、舒适的理念，酒店典雅而不失朴素的装修风格，尽可能向各位宾客提供“家”的感觉，是各界人士休闲度假、探胜仿古，单位团体组织召开会议、开设论坛、文化交流的上佳选择。
          </p>
          <p>
            酒店设计为明清庭院式青砖黛瓦建筑布局，风格古色古香，质朴大方。庭院内假山，回廊如时阆苑仙境，让人赏心悦目、心旷神怡。
          </p>
          <p>
            酒店设备设施配套适用、合理，通讯快捷，服务功能齐全，会议室等为阁下提供尽善尽美的服务。酒店附设行李寄存、洗衣等服务项目。
          </p>
          <p>
            酒店以“宾客至上，服务第一”为服务宗旨，真诚的为宾客提供“热情、周到、礼貌、快捷、安全、规范”的服务。
          </p> -->
        </div>
        <div class="introduce-content" v-else v-html="hotelData.hotelDetail">
          <p>
            酒壶山宾馆，为四星级酒店，位于黄姚古镇景区综合服务区内，距景区入口200米，是一座具有岭南风格特色的综合性宾馆。
          </p>
          <p>
            宾馆总体古色古香、明清庭院设计，青砖墙体、屋面黛瓦，透露着黄姚千年古镇风韵与气息，是现代艺术与古典美学的完美融合。
          </p>
          <p>
            宾馆设施先进，通讯快捷，拥有豪华双人房、商务套房、豪华套房120多间（套），中餐厅、豪华包间和多功能大小会议室等配套服务设施齐全。以“黄姚最好的宾馆，大众的消费”理念经营，是休闲度假、写生创作、商务会议、培训的最佳选择。
          </p>
        </div>
      </div>
      <div class="hotel-facility" id="ss">
        <span class="facility-title">酒店设施</span>
        <div class="facility-content">
          <span class="facility-item" v-for="(item,index) in hotelData.hotelFacilityNames" :key="index">
            <i class="el-icon-circle-check"></i> {{item}}
          </span>
        </div>
      </div>
      <div class="hotel-notice" id="xz">
        <span class="notice-title">预订须知</span>
        <p class="notice-content" v-if="!hotelData.hotelNotice">
          <span>入离时间14:00入住，12:00离店</span>
          <span>在智游黄姚古镇小程序预订成功后，凭预订短信到{{hotelData.hotelName}}前台办理入住手续。</span>
        </p>
        <p class="notice-content" v-else v-html="hotelData.hotelNotice"></p>
      </div>
      <div class="hotel-traffic" id="jt">
        <span class="traffic-title">交通信息</span>
        <Bmap></Bmap>
      </div>
    </div>
  </div>
</template>
<script>
import Spread from "../components/spread.vue";
import Bmap from "../components/bmap.vue";
import { Loading } from "element-ui";
export default {
  components: { Spread, Bmap },
  data() {
    return {
      mes2: "半岛酒店坐落在广西贺州市黄姚古镇景区内。秉承自然、时尚、舒适的理念，酒店设计为明清庭院式青砖黛瓦建筑布局，风格古色古香，质朴大方。典雅而不失朴素的装修风格，尽可能向各位宾客提供“家”的感觉，是各界人士休闲度假、探胜仿古，单位团体组织召开会议、开设论坛、文化交流的最佳选择。酒店设备设施配套适用、合理，通讯快捷，服务功能齐全，拥有豪华楼中楼复式房，标准单、豪华双人房共十四间，自助厨房和餐厅、会议室等为阁下提供尽善尽美的服务。",
      range: "1晚",
      time: "",
      hotelData: {},
      carouselImg: [],
      roomData: "",
      isShow: false,
      isfiexd: false,
      tabData: [
        { id: "1", title: "客房预订" },
        { id: "2", title: "酒店介绍" },
        { id: "5", title: "酒店设施" },
        { id: "3", title: "预订须知" },
        { id: "4", title: "交通信息" },
      ],
      selectId: 1,
      expireTimeOption: {
        disabledDate(date) {
          return date.getTime() < Date.now() - 24 * 60 * 60 * 1000;
        },
      }, //禁止选择当天之前的日期
    };
  },
  created() {
    
    let hotelId = this.$route.query.hotelId;
    this.$axios.get(this.apiUrl + "/app/api/hotel/" + hotelId).then((res) => {
      this.hotelData = res.data;
      this.carouselImg = this.hotelData.hotelCarouselImage.split(",").map(item=>{
        return this.imgUrl+item
      });
      // this.hotelData.openDate?this.hotelData.openDate=this.changeDate(this.hotelData.openDate):''
      this.hotelData.hotelFacilityNames?this.hotelData.hotelFacilityNames=this.hotelData.hotelFacilityNames.split(","):''
      this.hotelData.hotelFeatureThemeNames?this.hotelData.hotelFeatureThemeNames=this.hotelData.hotelFeatureThemeNames.split(","):''
      this.hotelData.hotelTypeNames?this.hotelData.hotelTypeNames=this.hotelData.hotelTypeNames.split(","):''
      // console.log(this.hotelData, "数据是啥");
    });
    this.getRoomData(hotelId);
  },
  mounted() {
    // 此处true需要加上，不加滚动事件可能绑定不成功
    window.addEventListener("scroll", this.handleScroll, true);
    setTimeout(() => {
      this.initMap();
    }, 1000);
  },
  //离开页面销毁滚动监听事件
  destroyed(){
    window.removeEventListener("scroll", this.handleScroll,true)
  },
  methods: {
    getRoomData(hotelId) {
      let loadingInstance;
      if (this.roomData !== "") {
        this.roomData = "";
        loadingInstance = Loading.service({
          text: "搜索中...",
          spinner: "el-icon-loading",
          target: document.querySelector(".room-info"),
        });
      }
      this.$axios
        .get(this.apiUrl + "/app/api/hotel/selectSysHotelRoomTypeList", {
          params: { hotelId: hotelId },
        })
        .then((res) => {
          this.roomData = res.rows;
          setTimeout(() => {
            this.isShow = true;
            loadingInstance.close();
          }, 300);

          // console.log(this.roomData, "数据是啥aaa");
        });
    },
    goback() {
      history.back(-1);
    },
    handleScroll() {
      let scrolltop =
        document.documentElement.scrollTop || document.body.scrollTop;
      scrolltop > 527 ? (this.isfiexd = true) : (this.isfiexd = false);
      if(this.isfiexd){
        document.querySelector(".unnecessary").style.height='50px'
        document.querySelector("#js").style.paddingTop='50px'
        document.querySelector("#ss").style.paddingTop='50px'
        document.querySelector("#yd").style.paddingTop='60px'
      }else{
        document.querySelector(".unnecessary").style.height='0'
        document.querySelector("#js").style.paddingTop='28px'
        document.querySelector("#ss").style.paddingTop='28px'
        document.querySelector("#yd").style.paddingTop='28px'
      }
    },
    goNode(id) {
      this.selectId = id;
      let selectorNama;
      if (id == 2) {
        selectorNama = "#js";
      } else if (id == 3) {
        selectorNama = "#xz";
      } else if (id == 4) {
        selectorNama = "#jt";
      }else if(id==5){
        selectorNama = "#ss";
      }
      if (id == 1) {
        document.getElementById("yd").scrollIntoView({
          behavior: "smooth", // 平滑过渡
          block: "start", // 上边框与视窗顶部平齐。默认值
        });
      } else {
        document.querySelector(selectorNama).scrollIntoView({
          behavior: "smooth", // 平滑过渡
          block: "start", // 上边框与视窗顶部平齐。默认值
        });
      }
    },
    book(id) {
      this.$router.push({
        path: "/ticketOrder",
        query: {
          hotelId: id,
          time: this.time,
        },
      });
    },
    changedeta(e) {
      if (e == null) {
        this.range = "1晚";
      } else {
        this.range = Math.floor((e[1] - e[0]) / (24 * 3600 * 1000)) + "晚";
      }
    },
    // 转化为xxxx年xx月xx日
		changeDate(openTime){
			let d = new Date(openTime);
			let d2 = d.getFullYear() + "年"+ (d.getMonth() + 1) +"月"+ d.getDate() +"日";
			return d2
		},
    initMap() {
      var center = new TMap.LatLng(
        Number(this.hotelData.latitude),
        Number(this.hotelData.longitude)
      );
      //初始化地图
      var map = new TMap.Map("hotel-map", {
        rotation: 20, //设置地图旋转角度
        pitch: 30, //设置俯仰角度（0~45）
        zoom: 15, //设置地图缩放级别
        center: center, //设置地图中心点坐标
      });
      var marker = new TMap.MultiMarker({
        map: map,
        styles: {
          // 点标记样式
          myStyle: new TMap.MarkerStyle({
            width: 20, // 样式宽
            height: 30, // 样式高
            color: "#F4531F",
            size: 14,
            direction: "bottom",
            anchor: { x: 10, y: 30 }, // 描点位置
            // src: "https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/markerDefault.png", // 标记路径
          }),
        },
        geometries: [
          // 点标记数据数组
          {
            styleId: "myStyle",
            // 标记位置(经度，纬度，高度)
            position: center,
            content: this.hotelData.hotelName,
          },
        ],
      });
    },
  },
};
</script>
<style scoped lang='scss'>
.hotel-detail {
  min-width: 1160px;
  width: 1000px;
  // height: 60px;
  min-height: calc(100vh - 220px);
  margin: 0 auto;
  .crumbs {
    font-size: 14px;
    font-weight: 400;
    color: rgba(29, 29, 29, 0.7);
    line-height: 30px;
    a {
      color: rgba(29, 29, 29, 0.7);
      &:hover {
        color: #c92b2f;
      }
    }
    span {
      cursor: pointer;
      &:nth-of-type(1) {
        &:hover {
          color: #c92b2f;
        }
      }
    }
  }
  .hotel-info {
    background: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    .hotel-title{
      display: flex;
      align-items: center;
      // margin-bottom: 10px;
    }
    .hotel-tags{
      margin-left: 15px;
    }
    .hotel-name {
      font-size: 20px;
      font-weight: bold;
    }
    p {
      font-size: 14px;
      color: #b2b3b5;
      line-height: 30px;
      word-wrap: break-word;
      i {
        margin-right: 8px;
      }
    }
    .hotel-img {
      display: inline-flex;
      .left {
        display: inline-flex;
        .big-img {
          margin-right: 8px;
          position: relative;
          img,.el-image {
            width: 352px;
            height: 270px;
            border-radius: 5px;
            display: block;
          }
          span {
            position: absolute;
            right: 15px;
            bottom: 15px;
            background: rgba(255, 255, 255, 0.9);
            border-radius: 3px;
            color: #505153;
            padding: 5px;
            display: block;
            font-size: 14px;
            line-height: 16px;
            cursor: pointer;
            .el-icon-picture {
              margin-right: 3px;
            }
          }
        }
        .small-img {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-content: space-between;
          width: 310px;
          img,.el-image {
            width: 150px;
            height: 130px;
            border-radius: 5px;
          }
          
        }
      }
      .right {
        margin-left: 16px;
        .hotel-services-type{
          display: flex;
          flex-wrap: wrap;
          height: 30px;
          span{
            margin-right: 10px;
          }
        }
        #hotel-map {
          width: 420px;
          height: 150px;
          ::v-deep.rotate-circle {
            display: none;
          }
        }
      }
    }
  }
  .main-warp {
    .main-content {
      background: #fff;
      .isfiexd {
        position: fixed;
        top: 0;
        background: #fff;
        z-index: 999;
        width: 1160px !important;
        box-shadow: 0 4px 16px 0 rgb(69 88 115 / 20%);
      }
      .tab-sort {
        width: 100%;
        display: flex;
        padding: 16px 0;
        .active {
          color: #e41f19;
          // font-weight: bold;
        }
        .sort-item {
          padding: 0 20px;
          font-size: 15px;
          cursor: pointer;
          position: relative;
          line-height: 18px;
          // &::after {
          //   content: "";
          //   width: 2px;
          //   height: 1em;
          //   background: #898989;
          //   position: absolute;
          //   top: 50%;
          //   transform: translateY(-50%);
          //   right: -1px;
          // }
        }
      }
    }
    .search-time {
      height: 40px;
      background: #fff;
      margin-bottom: 10px;
      padding: 20px;
      border-radius: 5px;
      margin-top: 20px;
      .el-date-editor {
        ::v-deep .el-range-separator {
          width: 10%;
        }
      }

      .el-button {
        margin-left: 12px;
        color: #fff;
        background: #f3581f;
      }
    }
    .room-info {
      ::v-deep.el-loading-mask {
        .el-loading-spinner {
          .el-icon-loading,
          .el-loading-text {
            color: #f3581f;
          }
        }
      }
      .room-type {
        border-radius: 5px;
        padding: 20px;
        width: 100%;
        margin-bottom: 9px;
        background: #fff;
        box-sizing: border-box;
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        &:last-of-type {
          margin-bottom: 0;
        }
        img {
          width: 120px;
          // height: 80px;
          border-radius: 5px;
          margin-right: 15px;
        }
        .room-item {
          &:nth-child(1) {
            width: 320px;
            display: inline-flex;
            align-items: center;
            div {
              span {
                font-size: 17px;
                line-height: 42px;
              }
              p {
                font-size: 12px;
                color: #6d7b7b;
                // line-height: 38px;
              }
            }
          }
          &:nth-child(2) {
            width: 400px;
            color: #6d7b7b;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          &:nth-child(3) {
            line-height: 80px;
            span {
              color: #f49a49;
              font-size: 18px;
              font-weight: bold;
            }
            .el-button {
              margin-left: 32px;
              color: #fff;
              background: #f3581f;
              font-size: 15px;
              position: relative;
              .code {
                width: 150px;
                height: 175px;
                position: absolute;
                right: -165px;
                top: -120px;
                display: none;
                background: #fff;
                img {
                  display: block;
                  width: 150px;
                  height: 150px;
                }
                p {
                  text-align: center;
                  color: #000;
                  line-height: 25px;
                }
              }
              &:hover {
                .code {
                  display: block;
                }
              }
            }
          }
        }
      }
    }
    .hotel-introduce {
      // height: 209px;
      background: #fff;
      border-radius: 5px;
      padding: 28px 30px;
      margin-top: 20px;
      .introduce-title {
        font-size: 20px;
        font-weight: bold;
        padding-left: 10px;
        position: relative;
        display: block;
        margin-bottom: 15px;
        &::before {
          content: "";
          width: 6px;
          height: 18px;
          background: #f3581f;
          border-radius: 5px;
          position: absolute;
          left: -6px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      .introduce-content {
        color: #6d7b7b;
        line-height: 28px;
        ::v-deep img{
          width: 100%;
        }
      }
    }
    .hotel-facility{
        // height: 120px;
        background: #fff;
        border-radius: 5px;
        padding: 28px 30px;
        margin-top: 20px;
      .facility-title{
        font-size: 20px;
        font-weight: bold;
        padding-left: 10px;
        position: relative;
        display: block;
        margin-bottom: 22px;
        &::before {
          content: "";
          width: 6px;
          height: 18px;
          background: #f3581f;
          border-radius: 5px;
          position: absolute;
          left: -6px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      .facility-content{
        color: #6d7b7b;
        display: flex;
        flex-wrap: wrap;
        .facility-item{
          width: 175px;
          padding: 0 8px 5px 0;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
      
    }
    .hotel-notice {
      height: 120px;
      background: #fff;
      border-radius: 5px;
      padding: 28px 30px;
      margin-top: 20px;
      .notice-title {
        font-size: 20px;
        font-weight: bold;
        padding-left: 10px;
        position: relative;
        display: block;
        margin-bottom: 22px;
        &::before {
          content: "";
          width: 6px;
          height: 18px;
          background: #f3581f;
          border-radius: 5px;
          position: absolute;
          left: -6px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      .notice-content {
        color: #6d7b7b;
        span {
          display: block;
          &:nth-child(2) {
            padding-top: 20px;
          }
        }
      }
    }
    .hotel-traffic {
      // height: 176px;
      background: #fff;
      border-radius: 5px;
      padding: 28px 30px;
      margin-top: 20px;
      .traffic-title {
        font-size: 20px;
        font-weight: bold;
        padding-left: 10px;
        position: relative;
        &::before {
          content: "";
          width: 6px;
          height: 18px;
          background: #f3581f;
          border-radius: 5px;
          position: absolute;
          left: -6px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
}
</style>
