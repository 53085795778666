<template>
  <div class="shop-detail">
    <p class="crumbs">
      <a href="http://huangyao.cn/index.html">首页</a> >
      <span @click="goback">产品抢购</span> >
      <span>{{ shopData.merchandiseName }}</span>
    </p>
    <div class="shop-info">
      <Spread :mes2="mes2"></Spread>
      <div class="shop-img">
        <div class="left">
          <div class="big-img">
            <img :src="imgUrl + shopData.coverImage" alt="" />
            <span><i class="el-icon-picture"></i>共有1张图片</span>
          </div>
        </div>
        <div class="right">
          <div class="goods-info">
            <div class="shop-title">
              <span class="shop-name">文创产品-{{ shopData.merchandiseName }}</span>
              <div class="shop-tags">
                <el-tag type="warning" size="mini" style="margin-right:10px;" v-for="item in shopData.merchandiseTagNames" :key="item">{{item}}</el-tag>
              </div>
            </div>
            <p>
              <i class="el-icon-location"></i>商品地址：黄姚古镇景区文创产品店
            </p>
            <p><i class="el-icon-collection"></i>{{ shopData.brief }}</p>
            <p class="goods-price">
              价格<span>￥{{ selectSalePrice }}</span>
            </p>
            <ul class="sku">
              <p>选择款式：</p>
              <li
                v-for="(item, index) in shopData.skuSelectedValue"
                :key="item.sku"
                @click="skuSelect(item, index)"
                :class="{ active: item.sku == selectSku }"
              >
                {{ item.sku }}
              </li>
            </ul>
            <p>
              数量：<el-input-number
                v-model="goodsNum"
                size="small"
                controls-position="right"
                :min="1"
                :max="shopData.inventorys"
              ></el-input-number>
              <span style="margin-left: 15px" v-if="shopData.skuSelectedValue"
                >库存：{{ shopData.skuSelectedValue[skuIndex].inventory }}</span
              >
            </p>
          </div>
          <div class="shop-order">
            <el-button @click="addCart(shopData)">加入购物车</el-button>
            <el-button @click="book(shopData.merchandiseId)"
              >立即预订
              <div class="code">
                <img src="../assets/code/shop-code.png" alt="" />
                <p>扫码预订</p>
              </div></el-button
            >
          </div>
          <div style="font-size: 14px;color: #b2b3b5;line-height:15px;" v-if="shopData.merchandiseServerNames">
            服务保障：
            <span v-for="item in shopData.merchandiseServerNames" :key="item" style="padding-right:5px;"><i class="el-icon-circle-check"></i>{{item}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="main-warp">
      <section class="main-content">
        <ul class="tab-sort" :class="{ isfiexd: isfiexd }">
          <li
            class="sort-item"
            :class="{ active: selectId == item.id }"
            v-for="item in tabData"
            :key="item.id"
            @click="goNode(item.id)"
          >
            {{ item.title }}
          </li>
        </ul>
      </section>
      <div class="unnecessary"></div>
      <div class="shop-introduce" id="js">
        <span class="introduce-title">商品介绍</span>
        <div class="introduce-content">
          <img :src="imgUrl + shopData.coverImage" alt="" />
        </div>
      </div>
      <div class="shop-notice" id="xz">
        <span class="notice-title">预订须知</span>
        <p class="notice-content" v-html="shopData.merchandiseNotice" v-if="shopData.merchandiseNotice">
          <span>1.图片仅供参考，以现场实物为准，</span>
          <span>2.预订完成，不支持退款。</span>
        </p>
        <p class="notice-content" v-else>
          <span>1.图片仅供参考，以现场实物为准；</span>
        </p>
      </div>
      <div class="shop-cost" id="fy">
        <span class="cost-title">费用说明</span>
        <p>费用说明</p>
      </div>
    </div>
  </div>
</template>
<script>
import Spread from "../components/spread.vue";
import { getToken } from "@/utils/auth";
export default {
  components: { Spread },
  data() {
    return {
      mes2: "半岛酒店坐落在广西贺州市黄姚古镇景区内。秉承自然、时尚、舒适的理念，酒店设计为明清庭院式青砖黛瓦建筑布局，风格古色古香，质朴大方。典雅而不失朴素的装修风格，尽可能向各位宾客提供“家”的感觉，是各界人士休闲度假、探胜仿古，单位团体组织召开会议、开设论坛、文化交流的最佳选择。酒店设备设施配套适用、合理，通讯快捷，服务功能齐全，拥有豪华楼中楼复式房，标准单、豪华双人房共十四间，自助厨房和餐厅、会议室等为阁下提供尽善尽美的服务。",
      shopData: {},
      goodsNum: 1,
      cartId: "",
      skuIndex: 0,
      selectSku: "",
      selectSalePrice: "",
      isfiexd: false,
      tabData: [
        { id: "1", title: "商品介绍" },
        { id: "2", title: "预订须知" },
        { id: "3", title: "费用说明" },
      ],
      selectId: 1,
    };
  },
  created() {
    let shopId = this.$route.query.shopId;
    this.$axios
      .get(this.apiUrl + "/app/api/merchandise/" + shopId)
      .then((res) => {
        this.shopData = res.data;
        this.selectSalePrice = this.shopData.salePrice;
        this.shopData.skuSelectedValue = JSON.parse(
          this.shopData.skuSelectedValue
        );
        if(this.shopData.merchandiseTagNames){
          this.shopData.merchandiseTagNames=this.shopData.merchandiseTagNames.split(',')
        }
        if(this.shopData.merchandiseServerNames){
          this.shopData.merchandiseServerNames=this.shopData.merchandiseServerNames.split(',')
        }
        // this.carouselImg = this.shopData.hotelCarouselImage.split( ',' )
        // console.log(this.shopData, "数据是啥");
      });
  },
  mounted() {
    // 此处true需要加上，不加滚动事件可能绑定不成功
    window.addEventListener("scroll", this.handleScroll, true);
  },
  // 离开页面销毁滚动监听事件
  destroyed(){
    window.removeEventListener("scroll", this.handleScroll,true)
  },
  methods: {
    goback() {
      history.back(-1);
    },
    handleScroll() {
      let scrolltop =
        document.documentElement.scrollTop || document.body.scrollTop;
      scrolltop > 540 ? (this.isfiexd = true) : (this.isfiexd = false);
      if (this.isfiexd) {
        document.querySelector(".unnecessary").style.height = "60px";
        document.querySelector("#js").style.paddingTop = "60px";
      } else {
        document.querySelector(".unnecessary").style.height = "0";
        document.querySelector("#js").style.paddingTop = "28px";
      }
    },
    goNode(id) {
      this.selectId = id;
      let selectorNama;
      if (id == 1) {
        selectorNama = "#js";
      } else if (id == 2) {
        selectorNama = "#xz";
      } else if (id == 3) {
        selectorNama = "#fy";
      }
      document.querySelector(selectorNama).scrollIntoView({
        behavior: "smooth", // 平滑过渡
        block: "start", // 上边框与视窗顶部平齐。默认值
      });
    },
    skuSelect(data, index) {
      this.skuIndex = index;
      this.selectSku = data.sku;
      this.selectSalePrice = data.salePrice;
    },
    addCart(shopData) {
      // console.log(shopData, "shopData");
      let isExist = false;
      if (getToken()) {
        let cartData = {
          buyType: 0,
          merchandiseId: shopData.merchandiseId,
          sku: this.selectSku,
          num: this.goodsNum,
          cartType: "0",
          salePrice: this.selectSalePrice,
          originalPrice: shopData.originalPrice,
        };
        if (this.selectSku == "") {
          this.$message({
            message: "请选择一个款式！",
            type: "warning",
          });
          return;
        }
        // 添加购物车前获取购物车数据
        this.$axios
          .get(this.apiUrl + "/app/api/cart/selectSysUserCartList", {
            params: { buyType: 0 },
          })
          .then((res) => {
            if (res.code == 1) {
              for (let i = 0; i < res.rows.length; i++) {
                if (
                  res.rows[i].merchandiseId == cartData.merchandiseId &&
                  res.rows[i].sku == cartData.sku
                ) {
                  isExist = true;
                  this.userCartChange(
                    this.goodsNum + res.rows[i].num,
                    res.rows[i].userCartId
                  );
                }
              }
              if (!isExist) {
                this.$axios
                  .post(this.apiUrl + "/app/api/cart", cartData)
                  .then((res) => {
                    if (res.code == 1) {
                      this.$message({
                        message: "添加购物车成功！",
                        type: "success",
                      });
                    }
                  });
              }
            }
          });
      } else {
        this.$store.commit("SET_SHOW", true);
      }
    },
    //如果购物车存在此类商品就改变数量
    userCartChange(number, id) {
      let numChange = {
        userCartId: id,
        num: number,
      };
      this.$axios.put(this.apiUrl + "/app/api/cart", numChange).then((res) => {
        if (res.code == 1) {
          this.$message({
            message: "添加购物车成功！",
            type: "success",
          });
        }
      });
    },
    book(id) {
      if (getToken()) {
        let cartData = {
          buyType: 0,
          merchandiseId: this.shopData.merchandiseId,
          sku: this.selectSku,
          num: this.goodsNum,
          cartType: "0",
          salePrice: this.selectSalePrice,
          originalPrice: this.shopData.originalPrice,
        };
        if (this.selectSku == "") {
          this.$message({
            message: "请选择一个款式！",
            type: "warning",
          });
          return;
        }
        this.$axios
          .post(this.apiUrl + "/app/api/cart", cartData)
          .then((res) => {
            if (res.code == 1) {
              this.cartId = res.data;
              this.$router.push({
                path: "/ticketOrder",
                query: {
                  shopId: id,
                  cartIds: this.cartId,
                },
              });
            }
          });
      } else {
        this.$notify({
          title: "提示",
          message: "请扫码登录后预订！",
          type: "warning",
          customClass: "zZindex",
        });
        this.$store.commit("SET_SHOW", true);
      }
    },
  },
};
</script>
<style lang="scss">
.zZindex {
  z-index: 2005 !important;
}
</style>
<style scoped lang='scss'>
.shop-detail {
  min-width: 1160px;
  width: 1000px;
  // height: 60px;
  min-height: calc(100vh - 220px);
  margin: 0 auto;
  .crumbs {
    font-size: 14px;
    font-weight: 400;
    color: rgba(29, 29, 29, 0.7);
    line-height: 30px;
    a {
      color: rgba(29, 29, 29, 0.7);
      &:hover {
        color: #c92b2f;
      }
    }
    span {
      cursor: pointer;
      &:nth-of-type(1) {
        &:hover {
          color: #c92b2f;
        }
      }
    }
  }
  .shop-info {
    background: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    .shop-title{
      display: flex;
      align-items: center;
      margin-bottom: 5px;
    }
    .shop-tags{
      margin-left: 15px;
    }
    .shop-name {
      font-size: 20px;
      font-weight: bold;
      display: block;
      line-height: 34px;
      white-space: nowrap;
    }
    p {
      font-size: 14px;
      color: #b2b3b5;
      line-height: 30px;
      word-wrap: break-word;
      i {
        margin-right: 8px;
      }
    }
    .shop-img {
      display: inline-flex;
      width: 100%;
      .left {
        display: inline-flex;
        .big-img {
          margin-right: 8px;
          position: relative;
          img {
            width: 550px;
            height: 340px;
            border-radius: 5px;
            display: block;
          }
          span {
            position: absolute;
            right: 15px;
            bottom: 15px;
            background: rgba(255, 255, 255, 0.9);
            border-radius: 3px;
            color: #505153;
            padding: 5px;
            display: block;
            font-size: 14px;
            line-height: 16px;
            cursor: pointer;
            .el-icon-picture {
              margin-right: 3px;
            }
          }
        }
        .small-img {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-content: space-between;
          width: 310px;
          img {
            width: 150px;
            height: 130px;
            border-radius: 5px;
          }
        }
      }
      .right {
        margin-left: 16px;
        display: inline-flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;
        .sku {
          margin: 0 0px 12px;
          display: flex;
          .active {
            border: 1px solid #e41f19;
            color: #e41f19;
            font-weight: 700;
            background: #fcedea;
          }
          li {
            min-width: 80px;
            max-width: 150px;
            height: 30px;
            border: 1px solid #b8b7bd;
            border-radius: 35px;
            text-align: center;
            color: #000;
            font-size: 15px;
            padding: 0 10px;
            margin-right: 8px;
            line-height: 30px;
            display: inline-flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            box-sizing: border-box;
            cursor: pointer;
            &:hover {
              border-color: #e41f19;
            }
          }
        }
        .goods-price {
          line-height: 52px;

          font-size: 16px;
          span {
            color: #ee652e;
            font-size: 22px;
            font-weight: bold;
            padding-left: 11px;
          }
        }
        .shop-order {
          display: inline-flex;
          width: 100%;
          // height: 48px;
          height: 40px;
          // background: #f7f9f8;
          .el-button {
            color: #fff;
            background: #f3581f;
            font-size: 15px;
            margin-right: 36px;
            position: relative;
            .code {
              width: 125px;
              height: 150px;
              position: absolute;
              right: -125px;
              top: -120px;
              display: none;
              background: #fff;
              img {
                display: block;
                width: 125px;
                height: 125px;
              }
              p {
                text-align: center;
                color: #000;
                line-height: 25px;
              }
            }
            &:hover {
              .code {
                display: block;
              }
            }
          }
        }
      }
    }
  }
  .main-warp {
    .main-content {
      background: #fff;
      margin-bottom: 20px;
      .isfiexd {
        position: fixed;
        top: 0;
        background: #fff;
        z-index: 999;
        width: 1160px !important;
        box-shadow: 0 4px 16px 0 rgb(69 88 115 / 20%);
      }
      .tab-sort {
        width: 100%;
        display: flex;
        padding: 16px 0;
        .active {
          color: #e41f19;
          // font-weight: bold;
        }
        .sort-item {
          padding: 0 20px;
          font-size: 15px;
          cursor: pointer;
          position: relative;
          line-height: 18px;
          // &::after {
          //   content: "";
          //   width: 2px;
          //   height: 1em;
          //   background: #898989;
          //   position: absolute;
          //   top: 50%;
          //   transform: translateY(-50%);
          //   right: -1px;
          // }
        }
      }
    }
    .room-info {
      margin-bottom: 20px;
      .room-type {
        border-radius: 5px;
        padding: 20px;
        width: 100%;
        margin-bottom: 9px;
        background: #fff;
        box-sizing: border-box;
        display: inline-flex;
        justify-content: space-between;
        align-content: center;
        &:last-of-type {
          margin-bottom: 0;
        }
        img {
          width: 120px;
          height: 80px;
          border-radius: 5px;
          margin-right: 15px;
        }
        .room-item {
          &:nth-child(1) {
            width: 350px;
            display: inline-flex;
            div {
              span {
                font-size: 17px;
                line-height: 42px;
              }
              p {
                font-size: 15px;
                color: #6d7b7b;
                line-height: 38px;
              }
            }
          }
          &:nth-child(2) {
            width: 360px;
            color: #6d7b7b;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          &:nth-child(3) {
            line-height: 80px;
            span {
              color: #f49a49;
              font-size: 18px;
              font-weight: bold;
            }
            .el-button {
              margin-left: 32px;
              color: #fff;
              background: #f3581f;
              font-size: 15px;
            }
          }
        }
      }
    }
    .shop-introduce {
      //   height: 209px;
      background: #fff;
      border-radius: 5px;
      padding: 28px 30px;
      .introduce-title {
        font-size: 20px;
        font-weight: bold;
        padding-left: 10px;
        position: relative;
        display: block;
        margin-bottom: 15px;
        &::before {
          content: "";
          width: 6px;
          height: 18px;
          background: #f3581f;
          border-radius: 5px;
          position: absolute;
          left: -6px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      .introduce-content {
        font-size: 14px;
        line-height: 28px;
        img {
          width: 950px;
          height: 500px;
          border-radius: 2px;
        }
      }
    }
    .shop-notice {
      height: 120px;
      background: #fff;
      border-radius: 5px;
      margin-top: 20px;
      padding: 28px 30px;
      .notice-title {
        font-size: 20px;
        font-weight: bold;
        padding-left: 10px;
        position: relative;
        display: block;
        margin-bottom: 22px;
        &::before {
          content: "";
          width: 6px;
          height: 18px;
          background: #f3581f;
          border-radius: 5px;
          position: absolute;
          left: -6px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      .notice-content {
        color: #6d7b7b;
        span {
          display: block;
          &:nth-child(2) {
            padding-top: 20px;
          }
        }
      }
    }
    .shop-cost {
      height: 176px;
      background: #fff;
      border-radius: 5px;
      padding: 28px 30px;
      margin-top: 20px;
      .cost-title {
        font-size: 20px;
        font-weight: bold;
        padding-left: 10px;
        display: block;
        margin-bottom: 15px;
        position: relative;
        &::before {
          content: "";
          width: 6px;
          height: 18px;
          background: #f3581f;
          border-radius: 5px;
          position: absolute;
          left: -6px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      p {
        color: #6d7b7b;
      }
    }
  }
}
</style>
