<template>
  <div class="page-content">
    <el-breadcrumb separator=">" class="crumb">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>我的主页</el-breadcrumb-item>
    </el-breadcrumb>
    <el-row class="block-col-2" :gutter="15">
      <el-col :span="5">
        <el-menu
          :default-openeds="openeds"
          class="left-menu"
          ref="menus"
          active-text-color="#F25320"
          @open="handleOpen"
          @close="handleClose"
          @select="handleSelect"
          :default-active="showPage"
          style="font-weight: bold"
        >
          <el-menu-item index="myPage">
            <span slot="title">我的主页</span>
          </el-menu-item>
          <el-submenu index="myOrder">
            <template slot="title">
              <span>我的订单</span>
            </template>
            <el-menu-item index="ticket">门票订单</el-menu-item>
            <el-menu-item index="guide">导游订单</el-menu-item>
            <el-menu-item index="hotel">酒店订单</el-menu-item>
            <el-menu-item index="shop">商品订单</el-menu-item>
          </el-submenu>
          <el-submenu index="info">
            <template slot="title">
              <span>常用信息</span>
            </template>
            <el-menu-item index="address">收货地址</el-menu-item>
            <el-menu-item index="consignee">游客信息</el-menu-item>
          </el-submenu>
        </el-menu>
      </el-col>
      <el-col :span="19">
        <!-- 我的主页 -->
        <div class="my-info" v-if="rightShow == 'myPage'">
          <div class="info-left">
            <img :src="userInfo.avatarUrl" alt="" />
            <div>
              <p class="user-name">{{ userInfo.nickName }}</p>
              <p>{{memberInfo.memberLevelName}}</p>
              <p><span>余额：￥0</span> <span> 积分：{{memberInfo.credits}}</span></p>
            </div>
          </div>
          <div class="info-right">
            <el-button type="text"
              >个人信息 <i class="el-icon-arrow-right"></i
            ></el-button>
          </div>
        </div>
        <!-- 我的订单 -->
        <el-tabs
          type="border-card"
          class="my-order"
          v-model="orderStatus"
          @tab-click="tabChange"
          v-if="
            rightShow == 'ticket' ||
            rightShow == 'guide' ||
            rightShow == 'hotel' ||
            rightShow == 'shop'
          "
        >
          <el-tab-pane label="全部订单" name="0">
            <ul class="order-content">
              <li
                class="order-item"
                v-for="item in ticketOrderList"
                :key="item.ticketOrderId"
                @click="
                  getDetails(
                    item.ticketOrderId || item.hotelOrderId || item.orderId
                  )
                "
              >
                <div>
                  <p>
                    {{ item.orderItemSnapshot.hotelName || "黄姚古镇景区" }}
                  </p>
                  <p style="font-size: 13px">
                    {{
                      item.orderItemSnapshot.roomTypeName ||
                      item.orderItemSnapshot.ticketName ||
                      item.orderItemSnapshot[0].sku + "..."
                    }}
                  </p>
                  <p>下单时间：{{ item.createTime }}</p>
                </div>
                <div>
                  <p>
                    订单金额 <span>￥{{ item.actualPaymentAmount }}</span>
                  </p>
                  <el-tag>{{ item.orderStatusName }}</el-tag>
                </div>
              </li>
              <li class="last" v-if="ticketOrderList.length !== 0">
                没有更多数据了
              </li>
              <div class="empty" v-else>
                <p><i class="iconfont icon-wodedingdan"></i></p>
                <p>您的订单空空如也，去看看有没有想玩的吧</p>
              </div>
            </ul>
          </el-tab-pane>
          <el-tab-pane label="待付款" name="1">
            <ul class="order-content">
              <li
                class="order-item"
                v-for="item in ticketOrderList"
                :key="item.ticketOrderId"
                @click="
                  getDetails(
                    item.ticketOrderId || item.hotelOrderId || item.orderId
                  )
                "
              >
                <div>
                  <p>
                    {{ item.orderItemSnapshot.hotelName || "黄姚古镇景区" }}
                  </p>
                  <p style="font-size: 13px">
                    {{
                      item.orderItemSnapshot.roomTypeName ||
                      item.orderItemSnapshot.ticketName ||
                      item.orderItemSnapshot[0].sku + "..."
                    }}
                  </p>
                  <p>下单时间：{{ item.createTime }}</p>
                </div>
                <div>
                  <p>
                    订单金额 <span>￥{{ item.actualPaymentAmount }}</span>
                  </p>
                  <el-tag>{{ item.orderStatusName }}</el-tag>
                </div>
              </li>
              <li class="last" v-if="ticketOrderList.length !== 0">
                没有更多数据了
              </li>
              <div class="empty" v-else>
                <p><i class="iconfont icon-wodedingdan"></i></p>
                <p>您的订单空空如也，去看看有没有想玩的吧</p>
              </div>
            </ul>
          </el-tab-pane>
          <el-tab-pane label="待使用" name="2">
            <ul class="order-content">
              <li
                class="order-item"
                v-for="item in ticketOrderList"
                :key="item.ticketOrderId"
                @click="
                  getDetails(
                    item.ticketOrderId || item.hotelOrderId || item.orderId
                  )
                "
              >
                <div>
                  <p>
                    {{ item.orderItemSnapshot.hotelName || "黄姚古镇景区" }}
                  </p>
                  <p style="font-size: 13px">
                    {{
                      item.orderItemSnapshot.roomTypeName ||
                      item.orderItemSnapshot.ticketName ||
                      item.orderItemSnapshot[0].sku + "..."
                    }}
                  </p>
                  <p>下单时间：{{ item.createTime }}</p>
                </div>
                <div>
                  <p>
                    订单金额 <span>￥{{ item.actualPaymentAmount }}</span>
                  </p>
                  <el-tag>{{ item.orderStatusName }}</el-tag>
                </div>
              </li>
              <li class="last" v-if="ticketOrderList.length !== 0">
                没有更多数据了
              </li>
              <div class="empty" v-else>
                <p><i class="iconfont icon-wodedingdan"></i></p>
                <p>您的订单空空如也，去看看有没有想玩的吧</p>
              </div>
            </ul>
          </el-tab-pane>
          <el-tab-pane label="已完成" name="3">
            <ul class="order-content">
              <li
                class="order-item"
                v-for="item in ticketOrderList"
                :key="item.ticketOrderId"
                @click="
                  getDetails(
                    item.ticketOrderId || item.hotelOrderId || item.orderId
                  )
                "
              >
                <div>
                  <p>
                    {{ item.orderItemSnapshot.hotelName || "黄姚古镇景区" }}
                  </p>
                  <p style="font-size: 13px">
                    {{
                      item.orderItemSnapshot.roomTypeName ||
                      item.orderItemSnapshot.ticketName ||
                      item.orderItemSnapshot[0].sku + "..."
                    }}
                  </p>
                  <p>下单时间：{{ item.createTime }}</p>
                </div>
                <div>
                  <p>
                    订单金额 <span>￥{{ item.actualPaymentAmount }}</span>
                  </p>
                  <el-tag>{{ item.orderStatusName }}</el-tag>
                </div>
              </li>
              <li class="last" v-if="ticketOrderList.length !== 0">
                没有更多数据了
              </li>
              <div class="empty" v-else>
                <p><i class="iconfont icon-wodedingdan"></i></p>
                <p>您的订单空空如也，去看看有没有想玩的吧</p>
              </div>
            </ul>
          </el-tab-pane>
          <el-tab-pane label="待评价" name="4" v-if="rightShow == 'shop'">
            <ul class="order-content">
              <li
                class="order-item"
                v-for="item in ticketOrderList"
                :key="item.ticketOrderId"
                @click="
                  getDetails(
                    item.ticketOrderId || item.hotelOrderId || item.orderId
                  )
                "
              >
                <div>
                  <p>黄姚古镇景区</p>
                  <p style="font-size: 13px">
                    {{ item.orderItemSnapshot[0].sku + "..." }}
                  </p>
                  <p>下单时间：{{ item.createTime }}</p>
                </div>
                <div>
                  <p>
                    订单金额 <span>￥{{ item.actualPaymentAmount }}</span>
                  </p>
                  <el-tag>{{ item.orderStatusName }}</el-tag>
                </div>
              </li>
              <li class="last" v-if="ticketOrderList.length !== 0">
                没有更多数据了
              </li>
              <div class="empty" v-else>
                <p><i class="iconfont icon-wodedingdan"></i></p>
                <p>您的订单空空如也，去看看有没有想玩的吧</p>
              </div>
            </ul>
          </el-tab-pane>
        </el-tabs>
        <!-- 常用信息-地址 -->
        <div class="address" v-if="rightShow == 'address'">
          <div class="address-list">
            <div class="address-title">已保存收货地址</div>
            <ul class="address-content" v-if="ticketOrderList.length !== 0">
              <li
                class="address-item"
                v-for="item in ticketOrderList"
                :key="item.consigneeAddressId"
              >
                <p>{{ item.consigneeName }}</p>
                <p>
                  {{ item.province }}{{ item.region
                  }}{{ item.consigneeAddress }}
                </p>
                <p>{{ item.consigneePhone }}</p>
                <p class="set-button">
                  <el-button
                    size="mini"
                    type="text"
                    @click="addressChange(item)"
                    >编 辑</el-button
                  >
                  |
                  <el-button
                    size="mini"
                    type="text"
                    @click="defaultChange(item)"
                    v-if="item.isDefault == '0'"
                    >设为默认</el-button
                  >

                  <el-tag type="danger" size="small" v-else>默认地址</el-tag>
                </p>
                <i
                  class="el-icon-error"
                  @click="addressDetele(item.consigneeAddressId)"
                ></i>
              </li>
            </ul>
            <div class="address-empty" v-else>
              <p><i class="iconfont icon-wudizhi"></i></p>
              <p>暂无收货地址，可在下方进行添加</p>
            </div>
          </div>
          <div class="add-address">
            <div class="add-address-title">
              {{ isAddressChange ? "编辑收货地址信息" : "新建收货地址信息" }}
            </div>
            <el-form
              label-position="right"
              label-width="100px"
              :model="formData"
              class="add-address-info"
              ref="ruleForm"
              :rules="rules"
            >
              <el-form-item label="收货人姓名" prop="consigneeName">
                <el-input
                  v-model="formData.consigneeName"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
              <el-form-item label="手机号码" prop="consigneePhone">
                <el-input
                  v-model="formData.consigneePhone"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
              <el-form-item label="所在地区" prop="region">
                <el-cascader
                  v-model="formData.region"
                  :options="areaData"
                  :props="defaultParams"
                  @change="areaChange"
                ></el-cascader>
              </el-form-item>
              <el-form-item label="详细地址" prop="consigneeAddress">
                <el-input
                  v-model="formData.consigneeAddress"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
              <el-form-item label="" prop="isDefault">
                <el-checkbox
                  label="设置为默认收货地址"
                  v-model="formData.isDefault"
                  true-label="1"
                  false-label="0"
                ></el-checkbox>
              </el-form-item>
              <el-form-item>
                <el-button
                  type="primary"
                  @click="changeAddressForm('ruleForm')"
                  v-if="isAddressChange"
                  >编 辑</el-button
                >
                <el-button type="primary" @click="submitForm('ruleForm')" v-else
                  >保 存</el-button
                >
                <el-button @click="resetForm('ruleForm')">重 置</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <!-- 常用信息-收货人信息 -->
        <div class="consignee" v-if="rightShow == 'consignee'">
          <div class="consignee-title">游客信息</div>
          <div class="consignee-content">
            <div class="consignee-button">
              <el-button
                type="primary"
                size="small"
                icon="el-icon-plus"
                @click="addConsignee"
                >新增游客信息</el-button
              >
              <!-- <el-button type="primary" size="small" disabled
                ><i class="iconfont icon-daoru" style="font-size: 13px"></i>
                批量导入</el-button
              > -->
            </div>
            <el-table
              ref="multipleTable"
              :data="tableData"
              tooltip-effect="dark"
              style="width: 100%"
              class="consignee-list"
              @selection-change="handleSelectionChange"
            >
              <el-table-column type="selection" width="55"> </el-table-column>
              <el-table-column label="姓名" width="120" prop="name">
              </el-table-column>
              <el-table-column prop="mobile" label="手机号" width="135">
              </el-table-column>
              <el-table-column
                prop="certificateTypeName"
                label="证件类型"
                width="120"
              >
              </el-table-column>
              <el-table-column
                prop="certificateNumber"
                label="证件号码"
                width="200"
              >
              </el-table-column>
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <!-- <el-button
                    type="danger"
                    size="mini"
                    @click="isDefault(scope.$index, scope.row)"
                    >设为默认</el-button
                  > -->
                  <el-button
                    size="mini"
                    type="text"
                    @click="consigneeEdit(scope.$index, scope.row)"
                    >编辑</el-button
                  >
                  <el-button
                    size="mini"
                    type="text"
                    @click="
                      consigneeDelete(scope.$index, scope.row.ticketPersonId)
                    "
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
            <div class="select-action">
              <!-- <el-button disabled @click="selectDload">下载联系人</el-button> -->
              <el-button
                :disabled="consigneeSelection.length !== 0 ? false : true"
                @click="selectDelete"
                type="danger"
                >删 除</el-button
              >
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- 新增联系人信息 -->
    <el-dialog
      :title="addTitle"
      :visible.sync="showAdd"
      width="30%"
      :before-close="addClose"
    >
      <el-form
        label-position="right"
        label-width="100px"
        :model="consigneeData"
        class="add-consignee-info"
        ref="consigneeForm"
        :rules="rules"
      >
        <el-form-item label="联系人姓名" prop="name">
          <el-input
            v-model="consigneeData.name"
            placeholder="请与证件姓名保持一致"
          ></el-input>
        </el-form-item>
        <el-form-item label="证件类型" prop="certificateType">
          <el-select
            v-model="consigneeData.certificateType"
            placeholder="请选择"
          >
            <el-option label="身份证" value="1"></el-option>
            <el-option label="港澳通行证" value="2"></el-option>
            <el-option label="返乡证" value="3"></el-option>
            <el-option label="护照" value="4"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="证件号码" prop="certificateNumber">
          <el-input
            v-model="consigneeData.certificateNumber"
            placeholder="请输入证件号码"
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号码" prop="mobile">
          <el-input
            v-model="consigneeData.mobile"
            placeholder="请输入手机号码"
          ></el-input>
        </el-form-item>
        <!-- <el-checkbox
          v-model="consigneeData.isDefault"
          style="margin: 0 0 20px 100px"
          >设为默认联系人</el-checkbox
        > -->
        <el-form-item>
          <el-button
            type="primary"
            v-if="addTitle == '新增游客信息'"
            @click="submitForm('consigneeForm')"
            >确 认</el-button
          >
          <el-button type="primary" v-else @click="reviseForm('consigneeForm')"
            >编 辑</el-button
          >
          <el-button @click="resetForm('consigneeForm')">取 消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 订单信息弹窗 -->
    <el-dialog
      title="订单详情"
      :visible.sync="showOrderInfo"
      :before-close="orderInfoClose"
      width="45%"
      top="5vh"
      center
      ><el-dialog
        width="15%"
        title="微信扫码支付"
        ref="paycode"
        :visible.sync="payShow"
        append-to-body
        center
        @close="payClose"
      >
        <div id="paycode"></div>
      </el-dialog>
      <div
        class="order-details"
        v-if="rightShow == 'ticket' || rightShow == 'guide'"
      >
        <div class="order-status">
          <div>
            <p>{{ orderInfo.orderStatusName }}</p>
            <p>订单金额：￥{{ orderInfo.actualPaymentAmount }}</p>
          </div>
          <div v-if="orderInfo.orderStatus == 1">
            <el-button
              size="small"
              @click="cancelOrder(orderInfo.ticketOrderId)"
              >取消订单</el-button
            >
            <el-button size="small" @click="payOrder(orderInfo.ticketOrderId)"
              >去支付</el-button
            >
          </div>
          <div v-else-if="orderInfo.orderStatus == 2">
            <el-button size="small" @click="refund(orderInfo.ticketOrderId)"
              >申请退款</el-button
            >
          </div>
          <div v-else-if="orderInfo.orderStatus == 4">
            <span>订单已关闭，如需购买，请再次下单。</span>
          </div>
        </div>
        <div class="order-goods">
          <el-descriptions
            title="商品信息"
            direction="vertical"
            :column="4"
            :contentStyle="{ 'font-size': '15px' }"
          >
            <el-descriptions-item label="商品名">
              {{ orderInfo.orderItemSnapshot.ticketName }}
            </el-descriptions-item>
            <el-descriptions-item label="使用日期"
              >{{ orderInfo.ticketDate }}
              {{ orderInfo.ticketDateWeek }}</el-descriptions-item
            >
            <el-descriptions-item label="有效期"
              >仅{{ orderInfo.ticketDate }}当天有效</el-descriptions-item
            >
            <el-descriptions-item label="换票地址"
              >{{ orderInfo.tradeAddress }}
            </el-descriptions-item>
            <el-descriptions-item label="使用地址">{{
              orderInfo.scenicAddress
            }}</el-descriptions-item>
          </el-descriptions>
        </div>
        <div class="tourist-info">
          <p>游客信息</p>
          <div
            class="info-content"
            v-for="item in orderInfo.orderItemSnapshot.ticketPerson"
            :key="item.ticketPersonId"
          >
            <div>
              <p>{{ item.name }}</p>
              <p>{{ item.mobile }}</p>
            </div>
            <div>
              <p>{{ item.certificateTypeName }}</p>
              <p>{{ item.certificateNumber }}</p>
            </div>
          </div>
        </div>
        <div class="contact-info" v-if="orderInfo.contactName">
          <p>联系人信息</p>
          <div>
            <p>{{ orderInfo.contactName }}</p>
            <p>{{ orderInfo.mobile }}</p>
          </div>
        </div>
        <div class="order-rules">
          <p>退改规则</p>
          <div>
            {{ orderInfo.returenRule }}
          </div>
        </div>
        <div class="order-info">
          <el-descriptions
            title="订单信息"
            direction="vertical"
            :column="4"
            :contentStyle="{ 'font-size': '15px' }"
          >
            <el-descriptions-item label="订单号">
              {{ orderInfo.orderNo }}
            </el-descriptions-item>
            <el-descriptions-item label="订单状态">{{
              orderInfo.orderStatusName
            }}</el-descriptions-item>
            <el-descriptions-item label="票型">{{
              orderInfo.orderItemSnapshot.ticketName
            }}</el-descriptions-item>
            <el-descriptions-item label="数量"
              >{{ orderInfo.ticketNum }}
            </el-descriptions-item>
            <el-descriptions-item label="单价"
              >￥{{ orderInfo.ticketPrice }}</el-descriptions-item
            >
            <el-descriptions-item label="游玩日期">{{
              orderInfo.ticketDate
            }}</el-descriptions-item>
            <el-descriptions-item label="创建时间">{{
              orderInfo.createTime
            }}</el-descriptions-item>
            <el-descriptions-item label="付款时间">{{
              orderInfo.payTime
            }}</el-descriptions-item>
            <el-descriptions-item label="核销时间">{{
              orderInfo.verificationTime
            }}</el-descriptions-item>
            <el-descriptions-item label="退款时间">{{
              orderInfo.refundTime
            }}</el-descriptions-item>
            <el-descriptions-item label="订单备注">{{
              orderInfo.remark
            }}</el-descriptions-item>
          </el-descriptions>
        </div>
        <div class="order-amount">
          <p>
            <span>订单总额</span><span>￥{{ orderInfo.totalAmount }}</span>
          </p>
          <p>
            <span>优惠券</span
            ><span>￥{{ orderInfo.couponAmount || "0" }}</span>
          </p>
          <p style="font-size: 16px; font-weight: bold">
            <span>合计：</span><span>￥{{ orderInfo.totalAmount }}</span>
          </p>
          <p
            style="font-size: 16px; font-weight: bold"
            v-if="orderInfo.orderStatus !== 1"
          >
            <span>实付款</span
            ><span style="color: red"
              >￥{{ orderInfo.actualPaymentAmount }}</span
            >
          </p>
        </div>
      </div>
      <div class="order-details" v-if="rightShow == 'hotel'">
        <div class="order-status">
          <div>
            <p>{{ orderInfo.orderStatusName }}</p>
            <p>订单金额：￥{{ orderInfo.actualPaymentAmount }}</p>
          </div>
          <div v-if="orderInfo.orderStatus == 1">
            <el-button size="small" @click="cancelOrder(orderInfo.hotelOrderId)"
              >取消订单</el-button
            >
            <el-button size="small" @click="payOrder(orderInfo.hotelOrderId)"
              >去支付</el-button
            >
          </div>
          <div v-else-if="orderInfo.orderStatus == 2">
            <el-button size="small" @click="refund(orderInfo.hotelOrderId)"
              >申请退款</el-button
            >
          </div>
          <div v-else-if="orderInfo.orderStatus == 4">
            <span>订单已关闭，如需购买，请再次下单。</span>
          </div>
        </div>
        <div class="order-info">
          <el-descriptions
            title="订单信息"
            direction="vertical"
            :column="4"
            :contentStyle="{ 'font-size': '15px' }"
          >
            <el-descriptions-item label="订单号">
              {{ orderInfo.orderNo }}
            </el-descriptions-item>
            <el-descriptions-item label="订单状态">{{
              orderInfo.orderStatusName
            }}</el-descriptions-item>
            <el-descriptions-item label="酒店名">{{
              orderInfo.orderItemSnapshot.hotelName
            }}</el-descriptions-item>
            <el-descriptions-item label="房型">{{
              orderInfo.orderItemSnapshot.roomTypeName
            }}</el-descriptions-item>
            <el-descriptions-item label="房间数量"
              >{{ orderInfo.roomNum }}
            </el-descriptions-item>
            <el-descriptions-item label="房间单价">{{
              orderInfo.roomPrice
            }}</el-descriptions-item>
            <el-descriptions-item label="入住时间">{{
              orderInfo.checkInTime
            }}</el-descriptions-item>
            <el-descriptions-item label="离开时间">{{
              orderInfo.departureTime
            }}</el-descriptions-item>
            <el-descriptions-item label="创建时间">{{
              orderInfo.createTime
            }}</el-descriptions-item>
            <el-descriptions-item label="付款时间">{{
              orderInfo.payTime
            }}</el-descriptions-item>
            <el-descriptions-item label="退款时间">{{
              orderInfo.refundTime
            }}</el-descriptions-item>
            <el-descriptions-item label="订单备注">{{
              orderInfo.remark
            }}</el-descriptions-item>
          </el-descriptions>
        </div>
        <div class="contact-info">
          <p>联系人信息</p>
          <div>
            <p>{{ orderInfo.contactName }}</p>
            <p>{{ orderInfo.mobile }}</p>
          </div>
        </div>
        <div class="tourist-info">
          <p>游客信息</p>
          <div
            class="info-content"
            v-for="item in orderInfo.orderItemSnapshot.roomPerson"
            :key="item.ticketPersonId"
          >
            <div>
              <p>{{ item.name }}</p>
              <p>{{ item.mobile }}</p>
            </div>
            <div>
              <p>{{ item.certificateTypeName }}</p>
              <p>{{ item.certificateNumber }}</p>
            </div>
          </div>
        </div>
        <div class="order-amount">
          <p>
            <span>房间总额</span><span>￥{{ orderInfo.totalAmount }}</span>
          </p>
          <p>
            <span>优惠券</span
            ><span>￥{{ orderInfo.couponAmount || "0" }}</span>
          </p>
          <p style="font-size: 16px; font-weight: bold">
            <span>合计：</span><span>￥{{ orderInfo.totalAmount }}</span>
          </p>
          <p
            style="font-size: 16px; font-weight: bold"
            v-if="orderInfo.orderStatus !== 1"
          >
            <span>实付款</span
            ><span style="color: red"
              >￥{{ orderInfo.actualPaymentAmount }}</span
            >
          </p>
        </div>
      </div>
      <div class="order-details" v-if="rightShow == 'shop'">
        <div class="order-status">
          <div>
            <p>{{ orderInfo.orderStatusName }}</p>
            <p>订单金额：￥{{ orderInfo.actualPaymentAmount }}</p>
          </div>
          <div v-if="orderInfo.orderStatus == 1">
            <el-button size="small" @click="cancelOrder(orderInfo.orderId)"
              >取消订单</el-button
            >
            <el-button size="small" @click="payOrder(orderInfo.orderId)"
              >去支付</el-button
            >
          </div>
          <div v-else-if="orderInfo.orderStatus == 2">
            <el-button size="small" @click="refund(orderInfo.orderId)"
              >申请退款</el-button
            >
          </div>
          <div v-else-if="orderInfo.orderStatus == 3">
            <el-button size="small" @click="confirmReceipt(orderInfo.orderId)"
              >确认收货</el-button
            >
          </div>
          <div v-else-if="orderInfo.orderStatus == 6">
            <span>订单已关闭，如需购买，请再次下单。</span>
          </div>
        </div>
        <div class="order-info">
          <el-descriptions
            title="订单信息"
            direction="vertical"
            :column="4"
            :contentStyle="{ 'font-size': '15px' }"
          >
            <el-descriptions-item label="订单号">
              {{ orderInfo.orderNo }}
            </el-descriptions-item>
            <el-descriptions-item label="订单状态">{{
              orderInfo.orderStatusName
            }}</el-descriptions-item>
            <el-descriptions-item label="收货方式">{{
              orderInfo.postageTypeName
            }}</el-descriptions-item>
            <el-descriptions-item label="快递公司"
              >{{ orderInfo.expressDeliveryName }}
            </el-descriptions-item>
            <el-descriptions-item label="快递单号"
              >{{ orderInfo.expressNumber }}
            </el-descriptions-item>
            <el-descriptions-item label="创建时间">{{
              orderInfo.createTime
            }}</el-descriptions-item>
            <el-descriptions-item label="付款时间">{{
              orderInfo.payTime
            }}</el-descriptions-item>
            <el-descriptions-item label="发货时间">{{
              orderInfo.deliveryTime
            }}</el-descriptions-item>
            <el-descriptions-item label="退款时间">{{
              orderInfo.refundTime
            }}</el-descriptions-item>
            <el-descriptions-item label="订单备注">{{
              orderInfo.remark
            }}</el-descriptions-item>
          </el-descriptions>
        </div>
        <div class="contact-info" v-if="orderInfo.isPostage==1">
          <p>收货人信息</p>
          <div>
            <p>{{ orderInfo.consigneeName }}<span style="padding:0 15px">{{ orderInfo.consigneePhone }}</span>{{ orderInfo.consigneeAddress }}</p>
            <p> </p>
          </div>
        </div>
        <div class="contact-info" v-else>
          <p>自提点信息</p>
          <div>
            <p>{{ orderInfo.pickUpContactPerson }}<span style="padding:0 15px">{{ orderInfo.pickUpContactPhone }}</span>{{ orderInfo.pickUpAddress }}</p>
          </div>
        </div>
        <div class="goods-details">
          <p>商品信息</p>
          <div class="goods-list">
            <el-table
              :data="orderInfo.orderItemSnapshot"
              :header-cell-style="{ 'text-align': 'center', padding: '5px' }"
              :cell-style="{ 'text-align': 'center', 'font-size': '15px' }"
            >
              <el-table-column label="商品图" min-width="20%">
                <template slot-scope="scope">
                  <img
                    :src="imgUrl + scope.row.coverImage"
                    alt=""
                    style="width: 100px; height: 100px"
                  />
                </template>
              </el-table-column>
              <el-table-column
                prop="merchandiseName"
                label="商品名"
                min-width="15%"
              >
              </el-table-column>
              <el-table-column
                prop="sku"
                label="商品属性"
                min-width="15%"
              >
              </el-table-column>
              <el-table-column prop="num" label="数量" min-width="9%">
              </el-table-column>
              <el-table-column
                prop="originalPrice"
                label="原价（￥）"
                min-width="13%"
              ></el-table-column>
              <el-table-column
                prop="salePrice"
                label="售价（￥）"
                min-width="13%"
              ></el-table-column>
              <el-table-column
                label="小计（￥）"
                min-width="15%"
              >
              <template slot-scope="scope">
                <span style="color:red;">{{scope.row.num*scope.row.salePrice}}</span>
                </template></el-table-column>
            </el-table>
            <div class="total-amount">
              <p>
                <span>商品总额：</span
                ><span>￥{{ orderInfo.totalAmount }}</span>
              </p>
              <p>
                <span>优惠券：</span
                ><span>￥{{ orderInfo.couponAmount || "0" }}</span>
              </p>
              <p>
                <span>配送费：</span><span>￥{{ orderInfo.postage }}</span>
              </p>
              <p style="font-size: 16px; font-weight: bold">
                <span>合计：</span
                ><span>￥{{ orderInfo.actualPaymentAmount }}</span>
              </p>
              <p
                style="font-size: 16px; font-weight: bold"
                v-if="orderInfo.orderStatus !== 1"
              >
                <span>实付款：</span
                ><span style="color: red"
                  >￥{{ orderInfo.actualPaymentAmount }}</span
                >
              </p>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="showOrderInfo = false">取 消</el-button> -->
        <el-button type="primary" @click="showOrderInfo = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import areaData from "../utils/area";
import { mapState, mapMutations, mapAction } from "vuex";
import QRCode from "qrcodejs2";
export default {
  data() {
    var validateMobilePhone = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("收货人手机号不可为空"));
      } else {
        var reg = /^1[3456789]\d{9}$/;
        if (!reg.test(value)) {
          callback(new Error("请输入有效的手机号码"));
          return;
        }
        callback();
      }
    };
    // 正则表达式->证件验证护照，身份证，返乡证，港澳通行证
    var validateNumber = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("证件号码不可为空"));
      } else {
        var reg =
          /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)|(^[HMhm]{1}([0-9]{10}|[0-9]{8})$)|((H|M)(\d{10})$)|(^1[45][0-9]{7}$|([P|p|S|s]\d{7}$)|([S|s|G|g]\d{8}$)|([Gg|Tt|Ss|Ll|Qq|Dd|Aa|Ff]\d{8}$)|([H|h|M|m]\d{8,10})$)/;
        if (!reg.test(value)) {
          callback(new Error("请输入有效的证件号码"));
          return;
        }
        callback();
      }
    };
    return {
      openeds: ["myOrder", "info"],
      rightShow: "myPage",
      showPage: "myPage",
      formData: {},
      consigneeData: {},
      areaData: areaData,
      showAdd: false,
      addTitle: "新增游客信息",
      showOrderInfo: false,
      consigneeSelection: [],
      ticketOrderList: [],
      orderStatus: "0",
      orderType: "",
      orderInfo: {
        orderItemSnapshot: [],
      },
      defaultParams: {
        label: "label",
        value: "label",
        children: "children",
      },
      rules: {
        name: [
          { required: true, message: "请输入游客姓名", trigger: "blur" },
        ],
        mobile: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          { validator: validateMobilePhone },
        ],
        certificateType: [
          { required: true, message: "请选择证件类型", trigger: "change" },
        ],
        certificateNumber: [
          { required: true, message: "请正确的证件号码", trigger: "blur" },
          { validator: validateNumber },
        ],
        consigneeName: [
          { required: true, message: "请输入收货人姓名", trigger: "blur" },
        ],
        consigneePhone: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          { validator: validateMobilePhone },
        ],
        region: [{ required: true, message: "请选择地区", trigger: "change" }],
        consigneeAddress: [
          { required: true, message: "请输入详细地址", trigger: "blur" },
        ],
      },
      tableData: [],
      payShow: false,
      timer: "",
      isAddressChange: false,
      memberInfo:{}
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo,
    }),
  },
  created() {
    //window.document.title = "我的主页";
    this.getMemberInfo()
  },
  mounted() {
    let rs = sessionStorage.getItem("rightShow");
    if (rs !== "undefined") {
      this.showPage = rs;
      this.handleSelect(rs);
      // sessionStorage.removeItem('rightShow')
    }
  },
  methods: {
    getMemberInfo(){
      this.$axios.get(this.apiUrl+"/app/api/user/getMemberInfo").then(res=>{
        if(res.code==1){
          this.memberInfo=res.data.data
        }
      })
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      //禁止左侧菜单收起
      this.$refs.menus.open(keyPath);
    },
    //左侧菜单选中事件
    handleSelect(e) {
      console.log("e==>>", e);
      this.rightShow = e;
      this.orderStatus = "0";
      let selectApi;
      switch (this.rightShow) {
        case "ticket":
          selectApi = "/app/api/ticketOrder/selectSysTicketOrderList";
          this.orderType = "1";
          break;
        case "hotel":
          selectApi = "/app/api/hotelOrder/selectSysHotelOrderList";
          break;
        case "shop":
          selectApi = "/app/api/order/selectSysOrderList";
          break;
        case "guide":
          selectApi = "/app/api/ticketOrder/selectSysTicketOrderList";
          this.orderType = "2";
          break;
        case "address":
          selectApi = "/app/api/address/selectSysConsigneeAddressList";
          break;
        case "consignee":
          selectApi = "/app/api/ticketPerson/selectSysTicketPersonList";
          break;
      }
      if (!selectApi) {
        return;
      } else {
        this.$axios
          .get(this.apiUrl + selectApi, {
            params: { pageSize: 10, pageNumber: 1, orderType: this.orderType },
          })
          .then((res) => {
            this.ticketOrderList = res.rows;
            for (let i = 0; i < this.ticketOrderList.length; i++) {
              if (this.ticketOrderList[i].orderItemSnapshot) {
                this.ticketOrderList[i].orderItemSnapshot = JSON.parse(
                  this.ticketOrderList[i].orderItemSnapshot
                );
              }
            }
            // this.ticketOrderList.orderItemSnapshot=JSON.parse(this.ticketOrderList.orderItemSnapshot)
            this.tableData = this.ticketOrderList;
            // console.log(this.ticketOrderList, "l");
          });
      }
    },
    //切换不同状态的订单
    tabChange(e) {
      let status;
      if (e.name == "0") {
        this.orderStatus = "0";
        status = "";
      } else {
        this.orderStatus = e.name;
        status = e.name;
      }
      let changeApi;
      switch (this.rightShow) {
        case "ticket":
          changeApi = "/app/api/ticketOrder/selectSysTicketOrderList";
          this.orderType = "1";
          break;
        case "hotel":
          changeApi = "/app/api/hotelOrder/selectSysHotelOrderList";
          break;
        case "shop":
          changeApi = "/app/api/order/selectSysOrderList";
          break;
        case "guide":
          changeApi = "/app/api/ticketOrder/selectSysTicketOrderList";
          this.orderType = "2";
          break;
      }
      this.$axios
        .get(this.apiUrl + changeApi, {
          params: { orderStatus: status, orderType: this.orderType },
        })
        .then((res) => {
          this.ticketOrderList = res.rows;
          for (let i = 0; i < this.ticketOrderList.length; i++) {
            if (this.ticketOrderList[i].orderItemSnapshot) {
              this.ticketOrderList[i].orderItemSnapshot = JSON.parse(
                this.ticketOrderList[i].orderItemSnapshot
              );
            }
          }
          // console.log(this.ticketOrderList, "l");
        });
    },
    // 获取订单详情
    getDetails(ticketOrderId) {
      let api;
      switch (this.rightShow) {
        case "ticket":
          api = "/app/api/ticketOrder/";
          break;
        case "hotel":
          api = "/app/api/hotelOrder/";
          break;
        case "shop":
          api = "/app/api/order/";
          break;
        case "guide":
          api = "/app/api/ticketOrder/";
          break;
      }
      this.$axios.get(this.apiUrl + api + ticketOrderId).then((res) => {
        this.orderInfo = res.data;
        this.orderInfo.orderItemSnapshot = JSON.parse(
          this.orderInfo.orderItemSnapshot
        );
        if (this.orderInfo.orderItemSnapshot.ticketPerson) {
          this.orderInfo.orderItemSnapshot.ticketPerson = JSON.parse(
            this.orderInfo.orderItemSnapshot.ticketPerson
          );
        } else if (this.orderInfo.orderItemSnapshot.roomPerson) {
          this.orderInfo.orderItemSnapshot.roomPerson = JSON.parse(
            this.orderInfo.orderItemSnapshot.roomPerson
          );
        }
        if (this.orderInfo.orderStatus == 2 && this.payShow == true) {
          this.$message({
            type: "success",
            message: "支付成功！可到门票订单查看",
          });
          this.payShow = false;
          clearInterval(this.timer);
        }
        // console.log(this.orderInfo, "l");
      });
      this.showOrderInfo = true;
    },
    // 取消订单
    cancelOrder(id) {
      let cancelApi;
      switch (this.rightShow) {
        case "ticket":
          cancelApi = "/app/api/ticketOrder/cancel";
          break;
        case "hotel":
          cancelApi = "/app/api/hotelOrder/cancel";
          break;
        case "shop":
          cancelApi = "/app/api/order/cancel";
          break;
        case "guide":
          cancelApi = "/app/api/ticketOrder/cancel";
          break;
      }
      if (this.rightShow == "shop") {
        this.$axios
          .post(this.apiUrl + cancelApi, { orderId: id })
          .then((res) => {
            if (res.code == 1) {
              this.$message({
                message: "取消订单成功！",
                type: "success",
              });
              this.getDetails(id);
            }
          });
      } else {
        this.$axios
          .put(this.apiUrl + cancelApi, { orderId: id })
          .then((res) => {
            if (res.code == 1) {
              this.$message({
                message: "取消订单成功！",
                type: "success",
              });
              this.getDetails(id);
            }
          });
      }
    },
    // 支付
    payOrder(orderId) {
      let payApi;
      if (this.rightShow == "ticket" || this.rightShow == "guide") {
        payApi = "/app/api/ticket/payOrder/";
      } else if (this.rightShow == "hotel") {
        payApi = "/app/api/hotel/payOrder/";
      } else {
        payApi = "/app/api/merchandise/payOrder/";
      }
      this.$axios.get(this.apiUrl + payApi + Number(orderId)).then((res) => {
        if (res.code == 1) {
          this.payShow = true;
          this.$nextTick(() => {
            this.getCoed(res.data.codeUrlStr);
          });
          this.timer = setInterval(() => {
            // 通过定时器每间隔一会去请求查询微信支付状态（具体参数根据项目需要而定）
            this.getDetails(orderId);
          }, 5000);
        } else {
          this.$notify.error({
            title: res.msg,
            message: "",
          });
        }
      });
    },
    // 生成付款二维码
    getCoed(code) {
      return new QRCode("paycode", {
        width: 200, // 宽
        height: 200, // 高
        text: code, // 二维码内容,也可以直接放接口获取的数据
        // render: 'canvas'     // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
        // background: '#f0f'   // 自定义颜色默认黑白
        // foreground: '#ff0'
      });
    },
    payClose() {
      this.payShow = false;
      const codeHtml = document.getElementById("paycode");
      codeHtml.innerHTML = "";
      clearInterval(this.timer);
    },
    // 退款
    refund(id) {
      let refundApi;
      switch (this.rightShow) {
        case "ticket":
          refundApi = "/app/api/ticketOrder/refundOrder";
          break;
        case "hotel":
          refundApi = "/app/api/hotelOrder/refundOrder";
          break;
        case "shop":
          refundApi = "/app/api/refundOrder";
          break;
        case "guide":
          refundApi = "/app/api/ticketOrder/refundOrder";
          break;
      }
      this.$confirm("确认申请退款？",'温馨提示',{
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios
            .post(this.apiUrl + refundApi, { orderId: id })
            .then((res) => {
              if (res.code == 1) {
                this.$message({
                  message: "待后台人员审核成功即退款金额将原路返回！",
                  type: "success",
                });
                this.getDetails(id);
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消申请",
          });
        });
    },
    // 确认收货
    confirmReceipt(id){
      this.$axios.post(this.apiUrl+'/app/api/order/confirmReceipt',{orderId:id}).then((res)=>{
        if(res.code==1){
           this.$message({
            message: "欢迎下次再来",
            type: "success",
          });
          this.getDetails(id);
        }
      })

    },
    //删除已保存的地址
    addressDetele(id) {
      this.$axios.delete(this.apiUrl + "/app/api/address/" + id).then((res) => {
        if (res.code == 1) {
          this.$message({
            message: "成功删除！",
            type: "success",
          });
          this.handleSelect("address");
        }
      });
    },
    areaChange(e) {
      console.log(e);
    },
    // 新增联系人/收货地址
    submitForm(formName) {
      let submitApi;
      let submitFormData;
      if (formName == "ruleForm") {
        submitApi = "/app/api/address";
        this.formData.province = this.formData.region[0];
        this.formData.city = this.formData.region[1];
        this.formData.region = this.formData.region[2];
        submitFormData = this.formData;
      } else {
        submitApi = "/app/api/ticketPerson";
        switch (this.consigneeData.certificateType) {
          case "1":
            this.consigneeData.certificateTypeName = "身份证";
            break;
          case "2":
            this.consigneeData.certificateTypeName = "港澳通行证";
            break;
          case "3":
            this.consigneeData.certificateTypeName = "返乡证";
            break;
          case "4":
            this.consigneeData.certificateTypeName = "护照";
            break;
        }
        submitFormData = this.consigneeData;
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$axios
            .post(this.apiUrl + submitApi, submitFormData)
            .then((res) => {
              if (res.code == 1) {
                this.$message({
                  message: "添加成功！",
                  type: "success",
                });
                if (formName == "ruleForm") {
                  this.resetForm(formName);
                  this.handleSelect("address");
                } else {
                  this.resetForm(formName);
                  this.handleSelect("consignee");
                }
              }
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    changeAddressForm(formName) {
      this.formData.province = this.formData.region[0];
      this.formData.city = this.formData.region[1];
      this.formData.region = this.formData.region[2];
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$axios
            .put(this.apiUrl + "/app/api/address", this.formData)
            .then((res) => {
              if (res.code == 1) {
                this.$message({
                  message: "编辑成功！",
                  type: "success",
                });
                this.resetForm(formName);
                this.handleSelect("address");
                this.isAddressChange=false
              }
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 收货地址编辑
    addressChange(addressData) {
      this.isAddressChange = true;
      this.formData = {
        consigneeName: addressData.consigneeName,
        consigneePhone: addressData.consigneePhone,
        consigneeAddress: addressData.consigneeAddress,
        isDefault: addressData.isDefault,
        consigneeAddressId: addressData.consigneeAddressId,
        userId: addressData.userId,
        region: [addressData.province, addressData.city, addressData.region],
      };
    },
    // 设置默认地址
    defaultChange(addressData) {
      let defaultData = {
        isDefault: "1",
        consigneeAddressId: addressData.consigneeAddressId,
        userId: addressData.userId,
      };
      this.$axios
        .put(this.apiUrl + "/app/api/address", defaultData)
        .then((res) => {
          if (res.code == "1") {
            this.handleSelect("address");
          }
        });
    },
    //重置表单
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.showAdd = false;
      this.consigneeData={}
    },
    // 修改联系人
    reviseForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$axios
            .put(this.apiUrl + "/app/api/ticketPerson", this.consigneeData)
            .then((res) => {
              if (res.code == 1) {
                this.$message({
                  message: "编辑成功！",
                  type: "success",
                });
                this.resetForm(formName);
                this.handleSelect("consignee");
              }
            });
        }
      });
    },
    // 收货人信息
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },

    handleSelectionChange(val) {
      this.consigneeSelection = [];
      for (let i = 0; i < val.length; i++) {
        this.consigneeSelection.push(val[i].ticketPersonId);
      }
      // console.log(this.consigneeSelection);
    },
    isDefault(index, row) {
      console.log(index, row);
    },
    //表格内操作->编辑
    consigneeEdit(index, row) {
      this.showAdd = true;
      this.addTitle = "编辑游客信息";
      this.consigneeData ={
        name:row.name,
        certificateType:row.certificateType,
        certificateNumber:row.certificateNumber,
        mobile:row.mobile,
        ticketPersonId:row.ticketPersonId,
        userId:row.userId
      } ;
    },
    //表格内操作->删除
    consigneeDelete(index, row) {
      this.consigneeSelection = row;
      this.selectDelete();
    },
    selectDload() {
      this.$message("功能尚未开发~");
    },
    //表格勾选删除
    selectDelete() {
      this.$axios
        .delete(
          this.apiUrl + "/app/api/ticketPerson/" + this.consigneeSelection
        )
        .then((res) => {
          if (res.code == 1) {
            this.$message({
              message: "成功删除！",
              type: "success",
            });
            this.handleSelect("consignee");
          }
        });
    },
    addConsignee() {
      this.addTitle='新增游客信息'
      this.showAdd = true;
    },
    addClose() {
      this.consigneeData = {};
      this.showAdd = false;
    },
    orderInfoClose() {
      clearInterval(this.timer);
      this.showOrderInfo = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.page-content {
  min-width: 1160px;
  width: 1000px;
  min-height: calc(100vh - 220px);
  margin: 0 auto;
  .crumb {
    line-height: 30px;
    ::v-deep .el-breadcrumb__item {
      color: rgba(29, 29, 29, 0.7);
      .el-breadcrumb__separator {
        font-weight: 500;
      }
    }
  }
  .el-menu {
    border: 1px solid #e6e6e6;
    border-radius: 5px;
    .el-menu-item {
      font-weight: normal;
    }
  }
  .left-menu {
    > .el-menu-item {
      font-weight: bold;
    }
  }
  .my-info {
    height: 140px;
    border: 1px solid #fed8cc;
    border-radius: 5px;
    background: #fff8f6;
    padding: 0 35px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .info-left {
      display: flex;
      align-items: center;
      img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        margin-right: 20px;
      }
      > div p {
        font-size: 15px;
        line-height: 25px;
        &:last-child {
          color: #8e908e;
        }
      }
      .user-name{
        font-size: 18px;
        font-weight: bold;
      }
    }
    .info-right {
      .el-button {
        color: #92918f;
      }
    }
  }
  .my-order {
    ::v-deep .el-tabs__header {
      .el-tabs__nav {
        .is-active {
          color: #f25320;
        }
        .el-tabs__item {
          &:hover {
            color: #f25320;
          }
        }
      }
    }
    .empty {
      padding-top: 50px;
      height: 415px;
      text-align: center;
      box-sizing: border-box;
      .iconfont {
        font-size: 100px;
        color: #eeeeee;
      }
      p {
        &:last-child {
          color: #8e908e;
          font-size: 14px;
        }
      }
    }
    ::v-deep .el-tabs__content {
      padding: 0;
    }
    .order-content {
      max-height: 645px;
      overflow: auto;
      .last {
        text-align: center;
        color: #8e908e;
        font-size: 14px;
        line-height: 50px;
      }
      .order-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #e9e9e9;
        padding: 18px;
        cursor: pointer;
        > div {
          &:last-child {
            p {
              color: #8e908e;
              font-size: 12px;
              display: inline;
              margin-right: 15px;
              span {
                font-size: 18px;
                font-weight: bold;
                color: #2c3e50;
              }
            }
          }
          p {
            &:last-child {
              color: #8e908e;
              font-size: 14px;
              margin-top: 5px;
            }
          }
        }
      }
    }
  }
  .address {
    border-radius: 5px;
    .address-list {
      border: 1px solid #e9e9e9;
      background: #fff;
      border-radius: 5px;
      margin-bottom: 15px;
      .address-title {
        line-height: 30px;
        padding-left: 15px;
        font-weight: bold;
        border-bottom: 1px solid #e9e9e9;
        background: #f9f9f9;
        font-size: 15px;
        border-radius: 5px 5px 0 0;
      }
      .address-content {
        display: flex;
        flex-wrap: wrap;
        padding: 15px;
        min-height: 180px;
        box-sizing: border-box;
        border-radius: 0 0 5px 5px;
        .address-item {
          width: 225px;
          height: 110px;
          border: 1px solid #e9e9e9;
          border-radius: 5px;
          margin-right: 15px;
          padding: 20px;
          position: relative;
          &:hover {
            i {
              display: block;
            }
          }
          p {
            line-height: 30px;
            &:nth-child(2),
            &:nth-child(3) {
              font-size: 14px;
              color: #8e908e;
            }
          }
          .set-button {
            text-align: right;
            color: #c0c0c0;
            .el-button{
              margin-left: 0;
            }
          }
          i {
            position: absolute;
            right: -8px;
            top: -8px;
            display: none;
            color: red;
            cursor: pointer;
          }
        }
      }
      .address-empty {
        height: 202px;
        text-align: center;
        box-sizing: border-box;
        .iconfont {
          font-size: 100px;
          color: #eeeeee;
        }
        p {
          &:last-child {
            color: #8e908e;
            font-size: 14px;
          }
        }
      }
    }
    .add-address {
      border: 1px solid #e9e9e9;
      border-radius: 5px;
      .add-address-title {
        line-height: 30px;
        padding-left: 15px;
        font-weight: bold;
        border-bottom: 1px solid #e9e9e9;
        background: #f9f9f9;
        font-size: 15px;
        border-radius: 5px 5px 0 0;
      }
      .add-address-info {
        padding: 15px;
        background: #fff;
        border-radius: 0 0 5px 5px;
      }
    }
  }
  .consignee {
    border: 1px solid #e9e9e9;
    border-radius: 5px;
    background: #fff;
    .consignee-title {
      line-height: 30px;
      padding-left: 15px;
      font-weight: bold;
      border-bottom: 1px solid #e9e9e9;
      background: #f9f9f9;
      font-size: 15px;
    }
    .consignee-content {
      padding: 15px;
      .consignee-button {
        margin-bottom: 10px;
      }
      .consignee-list {
        margin-bottom: 10px;
      }
      .select-action {
        margin-left: 75px;
      }
    }
  }
  .order-details {
    .order-status {
      width: 100%;
      height: 74px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid #e9e9e9;
      border-radius: 2px;
      margin-bottom: 10px;
      div {
        padding-left: 15px;
        p {
          line-height: 20px;
          &:nth-child(1) {
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 10px;
          }
        }
      }
      .el-button {
        width: 120px;
        height: 40px;
        margin-right: 15px;
        background: #f25320;
        color: #fff;
        font-weight: bold;
      }
    }
    .el-descriptions {
      padding-left: 15px;
      ::v-deep.el-descriptions__header {
        margin-bottom: 0;
      }
      ::v-deep.el-descriptions__body {
        .el-descriptions__table{
          .el-descriptions-row{
              .has-colon{
              &::after{
                content: '：';
              }
            }
          }
          
        }
      }
    }
    .tourist-info,
    .contact-info,
    .order-rules,
    .goods-details {
      padding: 0 15px;
      margin-bottom: 15px;
      > p {
        font-size: 16px;
        font-weight: 700;
      }
      .info-content {
        display: flex;
        justify-content: space-between;
      }
      .total-amount {
        padding-top: 15px;
        margin-left: 70%;
        p {
          display: flex;
          justify-content: space-between;
        }
      }
    }
    .order-amount {
      padding: 15px 15px 0;
      p {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
#paycode {
  margin: 0 auto;
  width: 200px;
}
</style>