<template>
  <div class="page-content">
    <el-breadcrumb separator=">" class="crumb">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>提交订单</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="ticket-order" v-if="orderType !== '3'">
      <el-form
        ref="form"
        :model="form"
        :rules="formRules"
        label-width="80px"
        class="order-info"
      >
        <h2 v-if="commodity.ticketCategoryId == 1">门票信息</h2>
        <h2 v-else-if="commodity.ticketCategoryId == 2">服务信息</h2>
        <h2 v-else>房型信息</h2>
        <el-form-item label="票型" v-if="commodity.ticketCategoryId == 1">
          <span>{{ commodity.ticketName }}</span>
        </el-form-item>
        <el-form-item label="服务" v-else-if="commodity.ticketCategoryId == 2">
          <span>{{ commodity.ticketName }}</span>
        </el-form-item>
        <el-form-item label="房型" v-else>
          <span>{{ commodity.roomTypeName }}</span>
        </el-form-item>
        <el-form-item label="金额">
          <span style="color: red; font-size: 16px; font-weight: bold"
            >￥{{ commodity.ticketPrice || commodity.roomPrice }}</span
          >
        </el-form-item>
        <el-form-item label="日期" v-if="orderType == '1'" prop="ticketDate">
          <el-date-picker
            v-model="form.ticketDate"
            type="date"
            placeholder="选择日期"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            :picker-options="expireTimeOption"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="日期" v-else prop="ticketDate">
          <el-date-picker
            v-model="form.ticketDate"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            type="daterange"
            range-separator="至"
            start-placeholder="入住日期"
            end-placeholder="离店日期"
            :picker-options="expireTimeOption"
            @change="stayDaysChange"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="数量">
          <el-input-number
            v-model="form.ticketNum"
            @change="numberChange"
            :min="1"
            :max="10"
            label="描述文字"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="联系人" prop="contactName">
          <el-input
            v-model="form.contactName"
            placeholder="请输入联系人姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="mobile">
          <el-input
            v-model="form.mobile"
            placeholder="请输入联系人电话"
          ></el-input>
        </el-form-item>
        <div
          v-for="(item, index) in form.ticketPersonJson"
          :key="item.hotelRoomTypeId"
        >
          <h2>游客信息{{ index + 1 }}</h2>
          <el-row>
            <el-col :span="20">
              <el-form-item
                :label="'姓名' + `${index + 1}`"
                :rules="formRules.name"
              >
                <el-input
                  v-model="form.ticketPersonJson[index].name"
                  placeholder="请输入游玩人姓名"
                  @input="change($event)"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item>
                <el-button type="danger" @click="showtouristData(index)"
                  >选择游客</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item label="手机号码" :rules="formRules.mobile">
            <el-input
              v-model="form.ticketPersonJson[index].mobile"
              placeholder="请输入游玩人手机号码"
              @input="change($event)"
            ></el-input>
          </el-form-item>
          <el-form-item label="证件类型" :rules="formRules.certificateType">
            <el-select
              v-model="form.ticketPersonJson[index].certificateType"
              placeholder="请选择证件类型"
              @change="$forceUpdate()"
            >
              <el-option label="身份证" value="1"></el-option>
              <el-option label="港澳通行证" value="2"></el-option>
              <el-option label="回乡证" value="3"></el-option>
              <el-option label="护照" value="4"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="证件号码" :rules="formRules.certificateNumber">
            <el-input
              v-model="item.certificateNumber"
              placeholder="请输入游玩人证件号码"
              @input="change($event)"
            ></el-input>
          </el-form-item>
        </div>
        <el-form-item label="备注" prop="remark">
          <el-input
            type="textarea"
            v-model="form.remark"
            placeholder="选填：可以填写其他说明"
          ></el-input>
        </el-form-item>
        <el-form-item label="优惠券">
          <el-select
            v-model="form.couponId"
            placeholder="选择优惠券"
            prop="couponId"
            clearable
            @change="couponChange"
            @clear="couponClear"
          >
            <el-option label="不使用优惠券" value=""></el-option>
            <el-option
              v-for="item in couponList"
              :key="item.couponId"
              :label="`省${item.couponAmount}元：${item.couponName}`"
              :value="{
                couponId: item.couponId,
                couponAmount: item.couponAmount,
              }"
              :disabled="item.allowUseAmount >= commodityAmount"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <el-checkbox
            :label="`积分抵扣（${creditInfo.credits}积分抵扣￥${creditInfo.creditsAmount}，共有${creditInfo.userCredits}积分）`"
            v-model="form.isCredits"
            @change="creditChange"
          ></el-checkbox>
        </el-form-item>
        <el-form-item>
          <span class="pay"
            >合计：<i>￥{{ commodityAmount }}</i></span
          >
          <el-button type="danger" @click="ticketSubmit('form')"
            >立即预订</el-button
          >
          <!-- <el-button @click="resetForm('form')">重置</el-button> -->
        </el-form-item>
      </el-form>
    </div>
    <!-- 商品订单 -->
    <div class="ticket-order" v-else>
      <!-- 自提or邮寄 -->
        <!-- <div class="select-postage" v-if="canSelectPostageType">
            <el-switch
            v-model="isPostage"
            @change="isPostageChange"
            active-text="自提"
            inactive-text="邮寄"
          ></el-switch>
        </div> -->
      <div class="delivery-address" v-if="!isPostage">
        <div class="address-title">
          <h2>选择收货地址</h2>
          <el-switch
            v-model="isPostage"
            @change="isPostageChange"
            active-text="自提"
            inactive-text="邮寄"
            v-if="canSelectPostageType"
          ></el-switch>
        </div>
        <ul class="address-content">
          <li
            class="address-item"
            v-for="item in addressList"
            :key="item.consigneeAddressId"
            @click="selected(item)"
            :class="{ active: item.consigneeAddressId == selectAddressId }"
          >
            <p>{{ item.consigneeName }}</p>
            <p>
              {{ item.province }}{{ item.region }}{{ item.consigneeAddress }}
            </p>
            <p>{{ item.consigneePhone }}</p>
            <i
              class="iconfont icon-youxiajiaogouxuan"
              :style="
                item.consigneeAddressId == selectAddressId
                  ? 'display:block;'
                  : ''
              "
            ></i>
            <el-tag type="danger" size="small" v-if="item.isDefault == 1"
              >默认地址</el-tag
            >
          </li>
          <li class="address-empty" v-if="addressList.length == 0">
            <p><i class="iconfont icon-wudizhi"></i></p>
            <p>暂无收货地址</p>
            <el-button type="danger" size="small" @click="addAddress"
              >新增收货地址</el-button
            >
          </li>
        </ul>
      </div>
      <div class="delivery-address pick-up-address" v-else>
        <div class="address-title">
          <h2>选择自提地址</h2>
          <el-switch
            v-model="isPostage"
            @change="isPostageChange"
            active-text="自提"
            inactive-text="邮寄"
            v-if="canSelectPostageType"
          ></el-switch>
        </div>
        
        <ul class="address-content">
          <li
            class="address-item"
            v-for="item in pickUpAddress"
            :key="item.pickUpAddressId"
            @click="pickUpSelected(item)"
            :class="{ active: item.pickUpAddressId == pickUpAddressId }"
          >
            <p>{{ item.contactPerson }}</p>
            <p>
              {{ item.address }}
            </p>
            <p>{{ item.contactPhone }}</p>
            <i
              class="iconfont icon-youxiajiaogouxuan"
              :style="
                item.pickUpAddressId == pickUpAddressId
                  ? 'display:block;'
                  : ''
              "
            ></i>
            <el-tag type="danger" size="small" v-if="item.isDefault == 1"
              >默认地址</el-tag
            >
          </li> 
        </ul>
      </div>
      <div class="goods-info">
        <!-- <h2>商品信息</h2> -->
        <el-descriptions
          :title="'商品信息' + `${index + 1}`"
          direction="vertical"
          :column="7"
          :contentStyle="{ 'font-weight': '600' }"
          v-for="(item, index) in goodsInfo"
          :key="item.merchandiseId"
        >
          <el-descriptions-item label="商品图"
            ><img
              :src="imgUrl + item.coverImage"
              alt=""
              style="width: 100px; height: 100px"
          /></el-descriptions-item>
          <el-descriptions-item label="商品名">{{
            item.merchandiseName
          }}</el-descriptions-item>
          <el-descriptions-item label="商品属性">{{
            item.sku
          }}</el-descriptions-item>
          <el-descriptions-item label="原价"
            >￥{{ item.originalPrice }}</el-descriptions-item
          >
          <el-descriptions-item label="销售单价">
            ￥{{ item.salePrice }}
          </el-descriptions-item>
          <el-descriptions-item label="数量"
            ><el-input-number
              v-model="item.num"
              @change="numChange(item.num, item.userCartId, item.inventory)"
              size="mini"
              style="width: 100px"
              :min="1"
              :max="item.inventory"
              v-if="item.num"
            ></el-input-number
            ><el-input-number
              v-else
              v-model="goodsNum"
              @change="numChange(goodsNum, '')"
              size="mini"
              style="width: 100px"
              :min="1"
              :max="99"
            ></el-input-number
          ></el-descriptions-item>

          <el-descriptions-item label="小计">
            <span style="color: red"
              >￥{{ Math.floor(item.num * item.salePrice * 100) / 100 }}</span
            >
          </el-descriptions-item>
        </el-descriptions>
        <div class="order-remark">
          <el-row :gutter="20">
            <el-col :span="15">
              订单备注：<el-input
                v-model="shopRemark"
                size="small"
                placeholder="选填：可以填写其他说明"
                type="textarea"
                maxlength="100"
                show-word-limit
              ></el-input>
            </el-col>
            <el-col :span="9">
              优惠券：
              <el-select
                v-model="couponId"
                placeholder="选择优惠券"
                style="width: 100%"
                clearable
                @change="couponChange"
                @clear="couponClear"
              >
                <el-option label="不使用优惠券" value=""></el-option>
                <el-option
                  v-for="item in couponList"
                  :key="item.couponId"
                  :label="`省${item.couponAmount}元：${item.couponName}`"
                  :value="{
                    couponId: item.couponId,
                    couponAmount: item.couponAmount,
                  }"
                  :disabled="item.allowUseAmount >= orderTotal"
                ></el-option>
              </el-select>
            </el-col>
          </el-row>
        </div>
        <div class="goods-total">
          <p>
            <el-checkbox
              v-model="isCredits"
              :label="`积分抵扣（${creditInfo.credits}积分抵扣￥${creditInfo.creditsAmount}，共有${creditInfo.userCredits}积分）`"
              border
              size="medium"
              @change="creditChange"
            ></el-checkbox>
          </p>
          <p>商品总额：￥{{ totalGoods }}</p>
          <p v-show="!isPostage">配送费：￥{{ postage }}</p>
          <p>优惠券：￥{{ couponAmount }}</p>
          <p style="font-weight: bold">
            合计：<span>￥{{ orderTotal }}</span>
          </p>
        </div>
      </div>
      <div class="submit-order">
        <!-- <span class="pay"
          >实付金额：<i>￥{{ orderTotal }}</i></span
        > -->
        <el-button type="danger" @click="ticketSubmit">提交订单</el-button>
      </div>
    </div>
    <!-- 微信支付二维码弹窗 -->
    <el-dialog
      title="请使用微信扫码支付"
      :visible.sync="showPay"
      width="15%"
      center
      :before-close="payClose"
    >
      <div id="paycode"></div>
    </el-dialog>
    <!-- 支付成功后 -->
    <el-dialog
      title=""
      :visible.sync="showResult"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="30%"
      center
    >
      <div class="result-info">
        <el-result icon="success" title="支付成功"> </el-result>
        <!-- <el-result icon="error" title="支付失败"> </el-result> -->
        <el-form label-width="100px">
          <el-form-item label="商品订单：">
            <span>{{ payResult.orderNo }}</span>
          </el-form-item>
          <el-form-item label="支付金额：">
            <span>{{ payResult.actualPaymentAmount }}</span>
          </el-form-item>
          <el-form-item label="商品名称：">
            <span
              >{{
                payResult.orderItemSnapshot.ticketName ||
                payResult.orderItemSnapshot.hotelName ||
                payResult.tradeName
              }}{{ payResult.orderItemSnapshot.roomTypeName || "" }}</span
            >
          </el-form-item>
          <el-form-item label="应付金额：">
            <span>{{ payResult.totalAmount }}</span>
          </el-form-item>
          <el-form-item label="支付方式：">
            <span>微信支付</span>
          </el-form-item>
          <el-form-item label="购买时间：">
            <span>{{ payResult.payTime }}</span>
          </el-form-item>
        </el-form>
        <p>若有疑问请与客服联系,我们将尽快为您提供服务。</p>
        <p>客服电话：待补充。。。</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="viewOrder">查看订单</el-button>
        <el-button type="primary" @click="goBack">返 回</el-button>
      </span>
    </el-dialog>
    <!-- 新增收货地址 -->
    <add-address
      :addAddressShow="addAddressShow"
      @changeShow="showAdd"
      @getShippingAddress="getShippingAddress"
    ></add-address>
    <!-- 选择游客弹窗 -->
    <el-dialog
      title="选择游客"
      :visible.sync="touristDataShow"
      center
      width="40%"
    >
      <el-table
        ref="multipleTable"
        :data="touristData"
        tooltip-effect="dark"
        class="tourist-list"
        @row-click="touristSelect"
      >
        <el-table-column width="60">
          <template slot-scope="scope">
            <el-radio
              class="tourist-radio"
              :label="scope.row.ticketPersonId"
              v-model="selectTouristId"
            ></el-radio>
          </template>
        </el-table-column>
        <el-table-column label="姓名" width="150" prop="name">
        </el-table-column>
        <el-table-column prop="mobile" label="手机号" width="150">
        </el-table-column>
        <el-table-column
          prop="certificateTypeName"
          label="证件类型"
          width="130"
        >
        </el-table-column>
        <el-table-column prop="certificateNumber" label="证件号码" width="220">
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="selectCancel">取 消</el-button>
        <el-button type="primary" @click="selectOk">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import QRCode from "qrcodejs2";
import addAddress from "../components/addAddress.vue";
import { Subtr, accAdd, accMul } from "@/utils/count";
export default {
  components: { addAddress },
  data() {
    // 证件验证护照，身份证，返乡证，港澳通行证
    var validateNumber = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("证件号码不可为空"));
      } else {
        var reg =
          /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)|(^[HMhm]{1}([0-9]{10}|[0-9]{8})$)|((H|M)(\d{10})$)|(^1[45][0-9]{7}$|([P|p|S|s]\d{7}$)|([S|s|G|g]\d{8}$)|([Gg|Tt|Ss|Ll|Qq|Dd|Aa|Ff]\d{8}$)|([H|h|M|m]\d{8,10})$)/;
        if (!reg.test(value)) {
          callback(new Error("请输入有效的证件号码"));
          return;
        }
        callback();
      }
    };
    return {
      form: {
        ticketDate: "",
        ticketNum: 1,
        contactName: "",
        mobile: "",
        remark: "",
        ticketId: "",
        couponId: "",
        isCredits: false,
        ticketType: "",
        ticketPersonJson: [
          {
            name: "",
            mobile: "",
            certificateType: "",
            certificateNumber: "",
            certificateTypeName: "",
          },
        ],
      },
      formRules: {
        ticketDate: [
          { required: true, message: "请选择日期", trigger: "change" },
        ],
        contactName: [
          { required: true, message: "请输入联系人姓名", trigger: "blur" },
        ],
        mobile: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "手机号码格式不正确",
            trigger: "blur",
          },
        ],
        certificateType: [
          { required: true, message: "请选择活动区域", trigger: "change" },
        ],
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        certificateNumber: [
          { required: true, message: "请输入证件号码", trigger: "blur" },
          { validator: validateNumber },
        ],
      },
      hotelForm: {
        ticketPersonJson: [
          {
            name: "",
            mobile: "",
            certificateType: "",
            certificateNumber: "",
            certificateTypeName: "",
          },
        ],
      },
      expireTimeOption: {
        disabledDate(date) {
          return date.getTime() < Date.now() - 24 * 60 * 60 * 1000;
        },
      }, //禁止选择当天之前的日期
      shopOrderData: {},
      addressList: {},
      pickUpAddress: [],
      orderType: "",
      goodsInfo: [],
      commodity: {},
      commodityAmount: 0,
      selectAddressId: "",
      pickUpAddressId: "",
      goodsNum: 1,
      orderTotal: 0,
      postage: 0,
      isPostage: false, //false邮寄true自提
      canSelectPostageType: false, //是否可自选邮寄方式
      orderId: "",
      shopRemark: "",
      couponId: "",
      couponAmount: 0,
      isCredits: false,
      showPay: false,
      timer: "", //定时器
      payResult: { orderItemSnapshot: "", tradeName: "" },
      showResult: false,
      addAddressShow: false,
      tmpArr: [],
      stayDays: 1, //住宿天数最少一天
      touristData: [], //游客信息
      selectTouristId: "",
      selectTouristData: {},
      touristDataShow: false,
      touristNumebr: 0,
      couponList: [], //优惠券列表
      creditInfo: {}, // 积分抵扣信息
    };
  },
  computed: {
    totalGoods() {
      if (this.isPostage) {
        this.postage = 0;
      }
      return Subtr(this.orderTotal, this.postage);
    },
  },
  created() {
    //window.document.title = "提交订单";
    if (this.$route.query.time) {
      this.tmpArr.push(new Date(Number(this.$route.query.time[0])));
      this.tmpArr.push(new Date(Number(this.$route.query.time[1])));
      this.form.ticketDate = this.tmpArr;
      this.stayDays = Math.floor(
        (this.$route.query.time[1] - this.$route.query.time[0]) /
          (24 * 3600 * 1000)
      );
    }
    if (this.$route.query.ticketId) {
      this.orderType = "1";
      this.getInfo(this.orderType, this.$route.query.ticketId);
    } else if (this.$route.query.hotelId) {
      this.orderType = "2";
      this.getInfo(this.orderType, this.$route.query.hotelId);
    } else {
      this.orderType = "3";
      this.getUserCart(this.$route.query.cartIds);
      // 如果从商品详情立即预订即有路由携带shopId
      if (this.$route.query.shopId) {
        this.getInfo(this.orderType, this.$route.query.shopId);
      }
    }
    //获取收货地址
    this.getShippingAddress();
    this.getPickUpAddress();
    this.getTourist();
    this.getMyCoupon();
  },
  // beforeDestroy() {
  //   clearInterval(this.timer);
  // },
  methods: {
    change(e) {
      this.$forceUpdate();
    },
    dateChange(date) {
      let Y = date.getFullYear() + "-";
      let M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      let D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate() + "";
      let newdate = Y + M + D;
      return newdate;
    },
    // 获取游客列表信息
    getTourist() {
      this.$axios
        .get(this.apiUrl + "/app/api/ticketPerson/selectSysTicketPersonList")
        .then((res) => {
          if (res.code == 1) {
            this.touristData = res.rows;
          }
        });
    },
    showtouristData(index) {
      this.touristDataShow = true;
      this.touristNumebr = index;
    },
    // 选择游客
    touristSelect(data) {
      this.selectTouristId = data.ticketPersonId;
      this.selectTouristData = data;
    },
    // 选择游客确认
    selectOk() {
      this.touristDataShow = false;
      this.form.ticketPersonJson[this.touristNumebr] = {
        name: this.selectTouristData.name,
        mobile: this.selectTouristData.mobile,
        certificateType: this.selectTouristData.certificateType,
        certificateNumber: this.selectTouristData.certificateNumber,
      };
    },
    //取消选择游客
    selectCancel() {
      if (this.selectTouristId !== "") {
        this.touristDataShow = false;
        this.form.ticketPersonJson[this.touristNumebr] =
          this.form.ticketPersonJson[this.touristNumebr];
      } else {
        this.selectTouristId = "";
        this.form.ticketPersonJson[this.touristNumebr] = {};
        this.touristDataShow = false;
      }
    },
    // 获取用户收货地址
    getShippingAddress() {
      this.$axios
        .get(this.apiUrl + "/app/api/address/selectSysConsigneeAddressList", {
          params: { pageSize: 10, pageNumber: 1 },
        })
        .then((res) => {
          this.addressList = res.rows;
          for (let i = 0; i < this.addressList.length; i++) {
            if (this.addressList[i].isDefault == "1") {
              this.selectAddressId = this.addressList[i].consigneeAddressId;
            }
          }
          // console.log(this.addressList, "addressList");
        });
    },
    // 获取自提地址
    getPickUpAddress() {
      this.$axios
        .get(this.apiUrl + "/app/api/pickUp/selectSysPickAddressList")
        .then((res) => {
          this.pickUpAddress = res.rows;
          this.pickUpAddressId=this.pickUpAddress[0].pickUpAddressId
        });
    },
    //从购物车跳转时获取勾选的结算商品
    getUserCart(cartIds) {
      this.$axios
        .get(this.apiUrl + "/app/api/cart/selectSysUserCartList", {
          params: { buyType: 0, cartIds: cartIds },
        })
        .then((res) => {
          this.goodsInfo = res.rows;
          for (let i = 0; i < this.goodsInfo.length; i++) {
            if (this.goodsInfo[i].postage !== 0) {
              this.postage = this.goodsInfo[i].postage;
            } else {
              this.postage = 0;
            }
          }
          this.initPostageType();
          // console.log(this.goodsInfo, "goods商品信息");
          this.countTotal(this.goodsInfo);
        });
    },
    //不加入购物车直接预订获取商品信息
    getInfo(type, id) {
      let api;
      switch (type) {
        case "1":
          api = "/app/api/ticket/";
          break;
        case "2":
          api = "/app/api/hotelRoomType/";
          break;
        case "3":
          api = "/app/api/merchandise/";
      }
      this.$axios.get(this.apiUrl + api + id).then((res) => {
        // this.goodsInfo.push(res.data);
        this.commodity = res.data;
        this.totalAmount(this.commodity);
        // this.countTotal(this.goodsInfo);
        // console.log(this.commodity, "预订的商品信息");
      });
    },
    // 获取优惠券
    getMyCoupon() {
      this.$axios
        .get(this.apiUrl + "/app/api/coupon/selectMyCouponList", {
          params: { available: 1 },
        })
        .then((res) => {
          if (res.code == 1) {
            this.couponList = res.rows;
          }
        });
    },
    // 优惠券选择
    couponChange(couponData) {
      console.log(couponData, "====>>");
      if (couponData !== "") {
        this.couponAmount = couponData.couponAmount;
        this.orderTotal = Subtr(this.orderTotal, couponData.couponAmount);
        this.commodityAmount = Subtr(
          this.commodityAmount,
          couponData.couponAmount
        );
      } else {
        this.orderTotal = this.orderTotal;
        this.commodityAmount = this.commodityAmount;
      }
    },
    // 取消选择优惠券
    couponClear() {
      if (this.orderType !== "3") {
        this.totalAmount(this.commodity);
      } else {
        this.countTotal(this.goodsInfo);
      }
      if (this.isCredits || this.form.isCredits) {
        this.orderTotal = Subtr(this.orderTotal, this.creditInfo.creditsAmount);
        this.commodityAmount = Subtr(
          this.commodityAmount,
          this.creditInfo.creditsAmount
        );
      }
      this.couponAmount = 0;
    },
    // 是否使用积分
    creditChange(e) {
      if (e == true) {
        this.orderTotal = Subtr(this.orderTotal, this.creditInfo.creditsAmount);
        this.commodityAmount = Subtr(
          this.commodityAmount,
          this.creditInfo.creditsAmount
        );
      } else {
        if (this.orderType !== "3") {
          this.totalAmount(this.commodity);
          this.commodityAmount = Subtr(this.commodityAmount, this.couponAmount);
        } else {
          this.countTotal(this.goodsInfo);
          this.orderTotal = Subtr(this.orderTotal, this.couponAmount);
        }
      }
    },
    // 获取积分抵扣信息
    getCredits(amount) {
      this.$axios
        .get(this.apiUrl + "/app/api/order/getCreditsInfo", {
          params: { actualPaymentAmount: amount },
        })
        .then((res) => {
          if (res.code == 1) {
            this.creditInfo = res.data.data;
          }
        });
    },
    // 判断是否有条件可以自选邮寄或自提
    initPostageType() {
      let hasSinglePostageType = false;
      // 1可邮寄，可自提 2只可邮寄 3只可自提
      this.goodsInfo.map((item) => {
        if (item.postageType == "2") {
          this.isPostage = false;
          hasSinglePostageType = true;
          return;
        }
        if (item.postageType == "3") {
          this.isPostage = true;
          hasSinglePostageType = true;
          this.orderTotal = Subtr(this.orderTotal, this.postage);
          return;
        }
      });
      if (!hasSinglePostageType) {
        this.canSelectPostageType = true;
      }
    },
    // 条件允许下切换邮寄方式
    isPostageChange(e) {
      console.log(e);
      if (e) {
        this.orderTotal = Subtr(this.orderTotal, this.postage);
        this.postage = 0;
      } else {
        this.getUserCart(this.$route.query.cartIds);
      }
    },
    // 支付后获取订单状态
    getOrderStatus(type, id) {
      let statusApi;
      let name = [];
      switch (type) {
        case "1":
          statusApi = "/app/api/ticketOrder/";
          break;
        case "2":
          statusApi = "/app/api/hotelOrder/";
          break;
        case "3":
          statusApi = "/app/api/order/";
      }
      this.$axios.get(this.apiUrl + statusApi + id).then((res) => {
        if (res.code == 1) {
          this.payResult = res.data;
          this.payResult.orderItemSnapshot = JSON.parse(
            this.payResult.orderItemSnapshot
          );
          if (this.payResult.orderItemSnapshot) {
            for (let i = 0; i < this.payResult.orderItemSnapshot.length; i++) {
              name.push(this.payResult.orderItemSnapshot[i].merchandiseName);
            }
            this.payResult.tradeName = name.toString();
          }
          if (res.data.orderStatus == 2) {
            this.$message({
              type: "success",
              message: "支付成功！可到我的订单查看",
              customClass: "zZindex",
            });
            if (type == "1" || type == "2") {
              this.resetForm("form");
            }
            this.showPay = false;
            clearInterval(this.timer);
            this.showResult = true;
          }
        }
      });
    },
    // 计算门票，导游，酒店合计金额
    totalAmount(data) {
      this.commodityAmount =
        (data.ticketPrice || data.roomPrice) *
        this.form.ticketNum *
        this.stayDays;
      if (!Number.isNaN(this.commodityAmount)) {
        this.getCredits(this.commodityAmount);
      }
    },
    // 计算商品总计金额
    countTotal(goods) {
      if (this.postage !== 0 && !this.isPostage) {
        this.orderTotal = this.postage;
      } else {
        this.orderTotal = 0;
        this.postage = 0;
      }
      for (let i = 0; i < goods.length; i++) {
        this.orderTotal += (goods[i].num || this.goodsNum) * goods[i].salePrice;
      }
      this.orderTotal = Math.floor(this.orderTotal.toFixed(2) * 100) / 100;
      this.getCredits(this.orderTotal);
      console.log(this.orderTotal, "总计");
    },
    //提交订单
    ticketSubmit(formName) {
      let submitApi;
      let submitData;
      let verification = false;
      if (formName == "form") {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            verification = true;
          } else {
            return false;
          }
        });
      }
      for (let i = 0; i < this.form.ticketPersonJson.length; i++) {
        switch (this.form.ticketPersonJson[i].certificateType) {
          case "1":
            this.form.ticketPersonJson[i].certificateTypeName = "身份证";
            break;
          case "2":
            this.form.ticketPersonJson[i].certificateTypeName = "港澳通行证";
            break;
          case "3":
            this.form.ticketPersonJson[i].certificateTypeName = "返乡证";
            break;
          case "4":
            this.form.ticketPersonJson[i].certificateTypeName = "护照";
            break;
        }
      }
      if (this.orderType == "1" && verification) {
        //门票和导游
        submitApi = "/app/api/ticketOrder";
        this.form.ticketId = this.commodity.ticketId;
        this.form.ticketType = this.commodity.ticketCategoryId;
        this.form.ticketPersonJson = JSON.stringify(this.form.ticketPersonJson);
        this.form.couponId = this.form.couponId.couponId;
        submitData = this.form;
      } else if (this.orderType == "2" && verification) {
        //酒店
        submitApi = "/app/api/hotelOrder";
        if (
          this.tmpArr.length !== 0 &&
          typeof this.form.ticketDate[0] !== "string"
        ) {
          this.form.ticketDate = [
            this.dateChange(this.tmpArr[0]),
            this.dateChange(this.tmpArr[1]),
          ];
        }
        this.hotelForm = {
          hotelId: this.commodity.hotelId,
          hotelRoomTypeId: this.commodity.hotelRoomTypeId,
          contactName: this.form.contactName,
          roomNum: this.form.ticketNum,
          remark: this.form.remark,
          roomDate: this.form.ticketDate.toString(),
          mobile: this.form.mobile,
          couponId: this.form.couponId.couponId,
          isCredits: this.form.isCredits,
          actualPaymentAmount:
            this.form.ticketNum * this.commodity.roomPrice * this.stayDays,
          roomPersonJson: JSON.stringify(this.form.ticketPersonJson),
          orderType: "1",
        };
        submitData = this.hotelForm;
      } else if (this.orderType == "3") {
        //商品
        submitApi = "/app/api/order";
        this.shopOrderData = {
          cartIds: this.$route.query.cartIds,
          addressId: this.selectAddressId,
          pickUpAddressId:this.pickUpAddressId,
          couponId: this.couponId.couponId,
          isCredits: this.isCredits,
          remark: this.shopRemark,
          postage: this.postage,
          buyType: "0",
          orderType: "1",
          isPostage: this.isPostage ? "0" : "1",
        };
        submitData = this.shopOrderData;
      }
      if (!submitApi) return;
      this.$axios.post(this.apiUrl + submitApi, submitData).then((res) => {
        if (res.code == 1) {
          this.$message({
            message: "订单添加成功！",
            type: "success",
          });
          this.orderId = res.data.orderId;
          this.pay(this.orderId, this.orderType);
        } else {
          this.$message({
            message: res.msg,
            type: "error",
          });
        }
      });
      this.form.ticketPersonJson = JSON.parse(this.form.ticketPersonJson); //防止提交后出现的问题
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 支付
    pay(orderId, orderType) {
      let payApi;
      if (orderType == "1") {
        payApi = "/app/api/ticket/payOrder/";
      } else if (orderType == "2") {
        payApi = "/app/api/hotel/payOrder/";
      } else {
        payApi = "/app/api/merchandise/payOrder/";
      }
      this.$axios.get(this.apiUrl + payApi + Number(orderId)).then((res) => {
        if (res.code == 1) {
          this.showPay = true;
          this.$nextTick(() => {
            this.getCoed(res.data.codeUrlStr);
          });
          this.timer = setInterval(() => {
            // 通过定时器每间隔一会去请求查询订单状态
            this.getOrderStatus(orderType, orderId);
          }, 3000);
        } else {
          this.$notify.error({
            title: res.msg,
            message: "",
          });
        }
      });
    },
    getCoed(code) {
      return new QRCode("paycode", {
        width: 200, // 宽
        height: 200, // 高
        text: code, // 二维码内容,也可以直接放接口获取的数据
        // render: 'canvas'     // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
        // background: '#f0f'   // 自定义颜色默认黑白
        // foreground: '#ff0'
      });
    },
    payClose() {
      this.$confirm("确认关闭？", "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const codeHtml = document.getElementById("paycode");
          codeHtml.innerHTML = "";
          clearInterval(this.timer);
          this.$message({
            type: "warning",
            message: "未成功支付，可在我的订单中查看继续支付",
            customClass: "zZindex",
          });
          this.$router.go(-1);
        })
        .catch(() => {});
    },
    stayDaysChange(date) {
      this.stayDays = Math.floor(
        (new Date(date[1]) - new Date(date[0])) / (24 * 3600 * 1000)
      );
      if (this.stayDays == 0) {
        this.stayDays = 1;
      }
      this.totalAmount(this.commodity);
    },
    //门票，导游，酒店更改数量
    numberChange(currentValue, oldValue) {
      console.log(currentValue, oldValue);
      if (oldValue < currentValue) {
        this.addItem();
        this.totalAmount(this.commodity);
      } else {
        this.deleteItem();
        this.totalAmount(this.commodity);
      }
      this.form.couponId = "";
      this.form.isCredits = false;
      // console.log(this.form.ticketPersonJson.length, "是多少");
    },
    addItem() {
      this.form.ticketPersonJson.push({
        name: "",
        mobile: "",
        certificateType: "",
        certificateNumber: "",
      });
    },
    deleteItem() {
      this.form.ticketPersonJson.pop();
      // console.log(this.form.ticketPersonJson, "删除");
    },
    //选中收货地址
    selected(data) {
      // console.log(data, "data11111");
      this.selectAddressId = data.consigneeAddressId;
    },
    // 选中自提地址
    pickUpSelected(data){
      this.pickUpAddressId=data.pickUpAddressId
    },
    //商品数量
    numChange(num, id, inventory) {
      if (num == inventory) {
        this.$notify({
          title: "警告",
          message: "已达该商品最大数量！",
          type: "warning",
        });
      }
      if (id !== "") {
        let numChange = {
          userCartId: id,
          num: num,
        };
        this.$axios
          .put(this.apiUrl + "/app/api/cart", numChange)
          .then((res) => {
            if (res.code == 1) {
              this.getUserCart(this.$route.query.cartIds);
              this.couponId = "";
              this.couponAmount = 0;
              this.isCredits = false;
            }
          });
      } else {
        this.countTotal(this.goodsInfo);
      }
    },
    viewOrder() {
      let rightShow;
      if (this.orderType == "1" && this.form.ticketType == "0") {
        rightShow = "ticket";
      } else if (this.orderType == "1" && this.form.ticketType == "1") {
        rightShow = "guide";
      } else if (this.orderType == "2") {
        rightShow = "hotel";
      } else {
        rightShow = "shop";
      }
      this.$router.push({ path: "/myHomePage" });
      sessionStorage.setItem("rightShow", rightShow);
    },
    goBack() {
      this.$router.go(-1);
    },
    addAddress() {
      this.addAddressShow = true;
    },
    showAdd(data) {
      if (data === "false") {
        this.addAddressShow = false;
      } else {
        this.addAddressShow = true;
      }
    },
  },
};
</script>
<style lang="scss">
.zZindex {
  z-index: 2005 !important;
}
</style>
<style scoped lang='scss'>
.page-content {
  min-width: 1160px;
  width: 1000px;
  // height: 60px;
  min-height: calc(100vh - 220px);
  margin: 0 auto;
  .crumb {
    line-height: 30px;
    ::v-deep .el-breadcrumb__item {
      color: rgba(29, 29, 29, 0.7);
      .el-breadcrumb__separator {
        font-weight: 500;
      }
    }
  }
}
.ticket-order {
  background: #fff;
  .order-info {
    background: #fff;
    padding: 20px;
    border-radius: 5px;
    h2 {
      font-weight: bold;
    }
  }
  .pay {
    margin-right: 20px;
    i {
      color: red;
      font-size: 16px;
      font-weight: bold;
    }
  }
  .delivery-address {
    .address-title{
      display: flex;
      justify-content: space-between;
      padding: 15px 15px 0;
    }
    h2 {
      
      font-weight: bold;
    }
  }
  .address-content {
    display: flex;
    flex-wrap: wrap;
    padding: 15px;
    min-height: 180px;
    box-sizing: border-box;
    border-radius: 0 0 5px 5px;
    .address-item {
      width: 225px;
      height: 110px;
      border: 1px solid #e9e9e9;
      border-radius: 5px;
      margin-right: 15px;
      padding: 20px;
      position: relative;
      cursor: pointer;
      p {
        // line-height: 35px;
        &:nth-child(2){
          padding:15px 0 ;
        }
        &:nth-child(2),
        &:nth-child(3) {
          font-size: 14px;
          color: #8e908e;
        }
      }
      .iconfont {
        display: none;
        position: absolute;
        right: -2px;
        bottom: -10px;
        font-size: 32px;
        color: red;
      }
      .el-tag {
        position: absolute;
        top: 5px;
        right: 5px;
      }
    }
    .active {
      border: 1.5px solid red;
    }
    .address-empty {
      height: 202px;
      text-align: center;
      box-sizing: border-box;
      margin: 0 auto;
      .iconfont {
        font-size: 80px;
        color: #eeeeee;
      }
      p {
        &:nth-of-type(2) {
          color: #8e908e;
          font-size: 15px;
          letter-spacing: 3px;
          padding-bottom: 15px;
        }
      }
    }
  }
  .pick-up-address {
    .address-content {
      .address-item {
        p {

        }
        .iconfont {
          display: none;
        }
      }
    }
  }
  .goods-info {
    padding: 15px;
    .el-descriptions {
      ::v-deep.el-descriptions__header {
        margin-bottom: 15px;
      }
    }
    .select-postage{
      text-align: right;
    }
    .goods-total {
      text-align: right;
      font-size: 15px;
      padding-top: 15px;
      span {
        color: red;
        font-size: 18px;
        font-weight: bold;
      }
      p {
        &:first-child {
          line-height: 35px;
        }
      }
    }
  }
  .submit-order {
    text-align: center;
    padding-bottom: 15px;
  }
}
#paycode {
  margin: 0 auto;
  width: 200px;
}
.result-info {
  .el-result {
    padding: 0 15px 20px;
  }
  .el-form {
    width: 70%;
    margin: 0 auto;
    .el-form-item {
      margin-bottom: 0px;
      ::v-deep .el-form-item__label {
        font-weight: bold;
      }
    }
  }
  p {
    color: #8e908e;
    width: 70%;
    margin: 0 auto;
    padding-left: 35px;
  }
}
.tourist-list {
  .tourist-radio {
    ::v-deep.el-radio__label {
      display: none;
    }
  }
}
</style>