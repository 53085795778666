<template>
  <div class="guide-content">
    <p class="crumbs"><a href="http://huangyao.cn/index.html">首页</a> > <span>导游预约</span></p>
    <section class="main-content">
      <ul class="tab-sort">
        <li class="sort-item active">智能排序</li>
        <li class="sort-item">好评优先</li>
        <li class="sort-item">人气优先</li>
        <li class="sort-item">价格最低</li>
        <li class="sort-item">价格最高</li>
      </ul>
    </section>
    <div class="guide-main">
      <div class="guide-list">
        <ul class="list-box">
          <li class="guide-item">
            <div class="left">
              <div class="guide-img">
                <a href="/guideDetail">
                  <img src="http://bibi-sbs.oss-cn-shanghai.aliyuncs.com/2021-11-24/1637755476547.jpg?Expires=2583835474&OSSAccessKeyId=LTAI4GJxALzftKu6WSo8n4oP&Signature=%2BDrPOWJWYoOmAIdeX0R5rlBsvM0%3D" alt="" />
                </a>
              </div>
              <div class="guide-msg">
                <span class="guide-name"
                  ><a href="/guideDetail">黄姚古镇景区导游服务 </a></span
                >
                <el-tag type="success" size="mini" style="margin-right: 5px"
                  >随买随用</el-tag
                >
                <el-tag type="warning" size="mini">不可退</el-tag>
                <p>语言：普通话、粤语、英语、日语、韩语、马来语</p>
              </div>
            </div>
            <div class="right">
              <div class="guide-info">
                <p><span>￥80</span>起</p>
                <button @click="go">立即预订<div class="code">
                  <img src="../assets/code/guide-code.png" alt="" />
                  <p>扫码预约</p>
                </div></button>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <!-- <div id="guide-map"></div> -->
    </div>
  </div>
</template>
<script>
// @ is an alias to /src

export default {
  name: "Hotel",
  data() {
    return {};
  },
  created(){
    //window.document.title='导游预约'
  },
  mounted() {
    this.initMap();
  },
  methods: {
    go() {
      this.$router.push("/guideDetail");
    },
    initMap() {
      var center = new TMap.LatLng(24.246103, 111.203767);
      //初始化地图
      var map = new TMap.Map("guide-map", {
        rotation: 20, //设置地图旋转角度
        pitch: 30, //设置俯仰角度（0~45）
        zoom: 15, //设置地图缩放级别
        center: center, //设置地图中心点坐标
      });
      var marker = new TMap.MultiMarker({
        map: map,
        styles: {
          // 点标记样式
          marker: new TMap.MarkerStyle({
            width: 20, // 样式宽
            height: 30, // 样式高
            anchor: { x: 10, y: 30 }, // 描点位置
            src: "https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/markerDefault.png", // 标记路径
          }),
        },
        geometries: [
          // 点标记数据数组
          {
            // 标记位置(经度，纬度，高度)
            position: new TMap.LatLng(24.246103, 111.203767),
          },
        ],
      });
    },
  },
};
</script>
<style scoped lang='scss'>
.guide-content {
  min-width: 1160px;
  width: 1000px;
  // height: 60px;
  min-height: calc(100vh - 220px);
  margin: 0 auto;
  .crumbs{
      font-size: 14px;
      font-weight: 400;
      color: rgba(29, 29, 29, 0.7);
      line-height: 30px;
    a{
      color: rgba(29, 29, 29, 0.7);
      &:hover{
        color: #C92B2F;
      }
    }
  }
  .main-content {
    background: #fff;
    .tab-sort {
      width: 100%;
      display: flex;
      padding: 16px 0;
      .sort-item {
        padding: 0 20px;
        font-size: 15px;
        line-height: 18px;
        cursor: pointer;
        position: relative;
        &::after {
          content: "";
          width: 2px;
          height: 1em;
          background: #898989;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: -1px;
        }
      }
    }
  }
  .guide-main {
    display: inline-flex;
    padding-top: 20px;
    .guide-list {
      .list-box {
        padding-right: 20px;
        .guide-item {
          width: 750px;
          height: 150px;
          background: #fff;
          box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
          border-radius: 5px;
          padding: 20px;
          margin-bottom: 20px;
          display: inline-flex;
          justify-content: space-between;
          .left {
            display: inline-flex;
            padding-right: 10px;
            border-right: 0.5px solid #f5f5f5;
            width: 530px;
            .guide-img {
              width: 180px;
              height: 150px;
              margin-right: 15px;
              img {
                width: 180px;
                height: 150px;
                border-radius: 3px;
              }
            }
            .guide-msg {
              .guide-name {
                display: block;
                font-weight: bold;
                font-size: 20px;
                margin-bottom: 8px;
                cursor: pointer;
                a {
                  color: #2c3150;
                  &:hover {
                    color: #f3581f;
                    font-weight: bold;
                  }
                }
              }
              p {
                font-size: 14px;
                color: #b2b3b5;
                margin: 8px 0;
              }
            }
          }
          .right {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            .guide-info {
              p {
                font-size: 14px;
                text-align: right;
                span {
                  font-size: 20px;
                  font-weight: bold;
                  color: #f49a49;
                }
              }
              button {
                width: 100px;
                height: 35px;
                color: #fff;
                font-size: 16px;
                line-height: 30px;
                background: #f4531f;
                border-radius: 5px;
                border: none;
                cursor: pointer;
                position: relative;
                .code {
                  width: 150px;
                  height: 175px;
                  position: absolute;
                  right: -150px;
                  top: -120px;
                  display: none;
                  background: #fff;
                  img{
                    display: block;
                    width: 150px;
                    height: 150px;
                  }
                  p{
                    text-align: center;
                    color: #000;
                    line-height: 25px;
                  }
                }
                &:hover {
                  .code {
                    display: block;
                  }
                }
              }
            }
          }
        }
      }
    }
    #guide-map {
      height: 350px;
      width: 100%;
    }
  }
}
</style>
