<template>
  <div class="shop-content">
    <p class="crumbs">
      <a href="http://huangyao.cn/index.html">首页</a> > <span>产品抢购</span>
    </p>
    <section class="main-content">
      <!-- <div class="screen">
        <div>
          分类：<span
            v-for="item in shopCategoryList"
            :key="item.merchandiseCategoryId"
            @click="CategoryChange(item.merchandiseCategoryId)"
            >{{ item.merchandiseCategoryName }}</span
          >
        </div>
        <div>
          标签：<span
            v-for="item in shopTagList"
            :key="item.dictValue"
            @click="tagChange(item.dictValue)"
            >{{ item.dictLabel }}</span
          >
        </div>
      </div> -->
      <ul class="tab-sort">
        <li class="sort-item" :class="item.selected?'sort-selected':''" v-for="item in dropdownList" :key="item.value" @click="sortSelect(item)">{{item.name}}</li>
        <!-- <li class="sort-item active">智能排序</li>
        <li class="sort-item">好评优先</li>
        <li class="sort-item">人气优先</li>
        <li class="sort-item">价格最低</li>
        <li class="sort-item">价格最高</li> -->
        <!-- <li class="sort-item">
          <el-input
            size="mini"
            v-model="paramsData.lowestPrice"
            placeholder="￥"
          ></el-input>
          -
          <el-input
            size="mini"
            v-model="paramsData.highestPrice"
            placeholder="￥"
          ></el-input>
          <el-button size="mini" type="danger" @click="priceRange"
            >确认</el-button
          >
        </li>
        <li class="sort-item"><el-button size="mini" type="danger" @click="reset">清空筛选内容</el-button></li> -->
      </ul>
    </section>
    <div class="shop-main">
      <div class="shop-list">
        <ul class="list-box" v-if="shopList.length != 0">
          <li
            class="shop-item"
            v-for="item in shopList"
            :key="item.merchandiseId"
          >
            <div class="left">
              <div class="shop-img">
                <a @click="go(item.merchandiseId)">
                  <img :src="imgUrl + item.coverImage" alt="" />
                </a>
              </div>
              <div class="shop-msg">
                <span class="shop-name"
                  ><a @click="go(item.merchandiseId)">{{
                    item.merchandiseName
                  }}</a></span
                >
                <!-- <p><i class="el-icon-location"></i>黄姚古镇景区古镇茶颜邮局旗舰店、睡仙榕茶颜店、文创茶颜店</p> -->
                <el-tag type="warning" size="mini">地方特产</el-tag>
                <p>
                  <i class="el-icon-location"></i>
                  商品地址：黄姚古镇景区文创产品店
                </p>
                <p><i class="el-icon-collection"></i> 黄姚特色、文创产品</p>
              </div>
            </div>
            <div class="right">
              <div class="shop-info">
                <p>
                  <span>￥{{ item.salePrice }}</span
                  >起
                </p>
                <button @click="go(item.merchandiseId)">
                  立即预订
                  <div class="code">
                    <img src="../assets/code/shop-code.png" alt="" />
                    <p>扫码预订</p>
                  </div>
                </button>
              </div>
            </div>
          </li>
        </ul>
        <div class="list-box empty" v-else>
          <p><i class="iconfont icon-zanwuneirong"></i></p>
          <p>暂无内容</p>
        </div>
        <!-- 分页 -->
        <el-pagination
          v-if="shopList.length != 0"
          background
          @size-change="pageSizeChange"
          @current-change="pageCurrentChange"
          prev-text="上一页"
          next-text="下一页"
          :current-page.sync="paramsData.pageNum"
          :page-sizes="[10, 15, 20]"
          :page-size="paramsData.pageSize"
          layout=" prev, pager, next,total, sizes, jumper"
          :total="listTotal"
        >
        </el-pagination>
      </div>
      <div class="shop-hot">
        <p class="hot-title">热门推荐</p>
        <div
          class="hot-list"
          v-for="item in hotList"
          :key="item.merchandiseId"
          @click="go(item.merchandiseId)"
        >
          <img :src="imgUrl + item.coverImage" alt="" />
          <p>文创产品-{{ item.merchandiseName }}</p>
          <p style="text-align: right; color: #d3ae6c">
            ￥{{ item.salePrice }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import { getToken, setToken, removeToken } from "@/utils/auth";
export default {
  name: "shop",
  data() {
    return {
      shopList: [],
      hotList: [],
      shopCategoryList: [],
      shopTagList: [],
      times:1,
      dropdownList: [
        {
          name: "综合排序",
          selected: true,
          value: "comprehensive",
        },
        {
          name: "销量",
          selected: false,
          value: "Desc",
        },
        {
          name: "价格从低到高",
          selected: false,
          value: "priceAsc",
        },
        {
          name: "价格从高到低",
          selected: false,
          value: "priceDesc",
        },
      ],
      listTotal: 0,
      paramsData: {
        comprehensiveSort: "comprehensive", //综合排序
        saleSort: "", //销量排序
        merchandiseCategoryId: "", //类别id
        merchandiseTagId: "", //商品标签id
        lowestPrice: "", //价格区间最低价格
        highestPrice: "", //价格区间最高价格
        pageNum: 1,
        pageSize: 10,
      },
    };
  },
  created() {
    this.getShopList();
    // this.getHotList();
    // this.getDictByTag();
    this.getShopCategory();
    if (this.$route.query.code) {
      this.$axios
        .post(
          this.apiUrl +
            "/app/api/user/wxQrLoginByCode?code=" +
            this.$route.query.code
        )
        .then((res) => {
          if (res.code == 1) {
            this.$store.commit("SET_USERINFO", res.data);
            this.$store.commit("SET_TOKEN", res.data.tokenValue);
            setToken(res.data.tokenValue);
          }
        });
    }
  },
  mounted() {},
  methods: {
    getShopList() {
      this.$axios
        .get(this.apiUrl + "/app/api/merchandise/selectSysMerchandiseList", {
          params: this.paramsData,
        })
        .then((res) => {
          this.shopList = res.rows;
          this.listTotal = res.total;
          if (this.paramsData.pageNum == 1&&this.times==1) {
            this.hotList = this.shopList.slice(0, 3);
            this.times+=1
          }
        });
    },
    getHotList() {
      this.$axios
        .get(this.apiUrl + "/app/api/merchandise/selectHotSearchList")
        .then((res) => {
          this.hotList = res.rows;
          console.log(this.hotList, "hotList");
        });
    },
    getDictByTag() {
      this.$axios
        .get(this.apiUrl + "/app/api/dictData/merchandise_server", {
          params: { dictType: "merchandise_server" },
        })
        .then((res) => {
          this.shopTagList = res.data;
        });
    },
    getShopCategory() {
      this.$axios
        .get(
          this.apiUrl +
            "/app/api/merchandise/selectSysMerchandiseCategoryList?categoryLevel=2"
        )
        .then((res) => {
          this.shopCategoryList = res.rows;
        });
    },
    CategoryChange(id) {
      this.paramsData.merchandiseCategoryId = id;
      this.getShopList();
    },
    tagChange(val) {
      this.paramsData.merchandiseTagId = val;
      this.getShopList();
    },
    sortSelect(e){
      for(let i=0;i<this.dropdownList.length;i++){
        if(e.value==this.dropdownList[i].value){
          this.dropdownList[i].selected=true
          if(i==1){
            this.paramsData.saleSort=e.value
          }else{
            this.paramsData.saleSort=''
            this.paramsData.comprehensiveSort=e.value
          }
        }else{
          this.dropdownList[i].selected=false
        }
      }
      this.getShopList()
    },
    // 价格区间筛选
    priceRange() {
      this.getShopList();
    },
    reset(){
      this.paramsData={
        comprehensiveSort: "comprehensive", //综合排序
        saleSort: "", //销量排序
        merchandiseCategoryId: "", //类别id
        merchandiseTagId: "", //商品标签id
        lowestPrice: "", //价格区间最低价格
        highestPrice: "", //价格区间最高价格
        pageNum: 1,
        pageSize: 10,
      }
      this.getShopList()
    },
    // 分页操作
    pageCurrentChange(e) {
      this.paramsData.pageNum = e;
      this.getShopList();
    },
    pageSizeChange(e) {
      this.paramsData.pageSize = e;
      this.getShopList();
    },
    // 跳转详情页
    go(id) {
      this.$router.push({ path: "/shopDetail", query: { shopId: id } });
    },
    // low(){
    //   this.shopList =this.shopList.sort(this.compare('salePrice'))
    // },
    // 排序方法
    compare(property) {
      //property:根据什么属性排序
      return function (a, b) {
        var value1 = a[property];
        var value2 = b[property];
        /*
         * value2 - value1; ——> 降序
         * value1 - value2; ——> 升序
         */
        return value1 - value2; //升序排序
      };
    },
  },
};
</script>
<style scoped lang='scss'>
.shop-content {
  min-width: 1160px;
  width: 1000px;
  margin: 0 auto;
  .crumbs {
    font-size: 14px;
    font-weight: 400;
    color: rgba(29, 29, 29, 0.7);
    line-height: 30px;
    a {
      color: rgba(29, 29, 29, 0.7);
      &:hover {
        color: #c92b2f;
      }
    }
  }
  .main-content {
    background: #fff;
    // padding-top: 20px;
    .screen {
      padding-left: 20px;
      div > span {
        padding-right: 10px;
        font-size: 15px;
        line-height: 30px;
        cursor: pointer;
        &:hover{
          color: red;
        }
      }
    }
    .tab-sort {
      width: 100%;
      display: flex;
      padding: 16px 0;
      .sort-item {
        padding: 0 20px;
        font-size: 15px;
        cursor: pointer;
        position: relative;
        line-height: 30px;
        &::after {
          content: "";
          width: 2px;
          height: 1em;
          background: #898989;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: -1px;
        }
        .el-input {
          display: inline-block;
          width: 72px;
          height: 30px;
          line-height: 30px;
        }
        .el-button {
          margin-left: 10px;
        }
        &:hover{
          color: red;
        }
      }
      .sort-selected{
        color: red;
      }
    }
  }
  .shop-main {
    display: inline-flex;
    padding-top: 20px;
    .shop-list {
      width: 860px;
      .list-box {
        padding-right: 20px;
        .shop-item {
          width: 800px;
          height: 150px;
          background: #fff;
          box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
          border-radius: 5px;
          padding: 20px;
          margin-bottom: 20px;
          display: inline-flex;
          justify-content: space-between;
          .left {
            display: inline-flex;
            padding-right: 10px;
            border-right: 0.5px solid #f5f5f5;
            width: 570px;
            .shop-img {
              width: 180px;
              height: 150px;
              margin-right: 15px;
              cursor: pointer;
              img {
                width: 180px;
                height: 150px;
                border-radius: 3px;
              }
            }
            .shop-msg {
              .shop-name {
                display: block;
                font-weight: bold;
                font-size: 20px;
                margin-bottom: 8px;
                cursor: pointer;
                a {
                  color: #2c3150;
                  &:hover {
                    color: #f3581f;
                    font-weight: bold;
                  }
                }
              }
              p {
                font-size: 12px;
                color: #b2b3b5;
                margin: 8px 0;
              }
            }
          }
          .right {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            .shop-info {
              p {
                font-size: 14px;
                text-align: right;
                margin-bottom: 10px;
                span {
                  font-size: 20px;
                  font-weight: bold;
                  color: #f49a49;
                }
              }
              button {
                width: 100px;
                height: 35px;
                color: #fff;
                font-size: 16px;
                line-height: 30px;
                background: #f4531f;
                border-radius: 5px;
                border: none;
                cursor: pointer;
                position: relative;
                .code {
                  width: 150px;
                  height: 175px;
                  background: #fff;
                  position: absolute;
                  right: -150px;
                  top: -120px;
                  display: none;
                  img {
                    display: block;
                    width: 150px;
                    height: 150px;
                  }
                  p {
                    text-align: center;
                    color: #000;
                    line-height: 25px;
                  }
                }
                &:hover {
                  .code {
                    display: block;
                  }
                }
              }
            }
          }
        }
      }
      .el-pagination {
        text-align: center;
      }
      .empty {
        padding-top: 50px;
        height: 415px;
        text-align: center;
        box-sizing: border-box;
        .iconfont {
          font-size: 100px;
          color: #eeeeee;
        }
        p {
          &:last-child {
            color: #8e908e;
            font-size: 14px;
          }
        }
      }
      ::v-deep .el-pagination {
          text-align: center;
          .btn-prev,
          .btn-next {
            box-sizing: content-box;
            padding: 1px 22px;
            border: 1px solid rgba($color: #fff, $alpha: 0.5);
            text-align: center;
            border-radius: 20px;
            color: #fff;
            margin-right: 10px;
            background: rgba(244,83,31,0.9);
          }
          .el-pager {
            .number,
            .more {
              padding: 15px 22px;
              line-height: 0px;
              text-align: center;
              border-radius: 20px;
              color: #fff;
              border: 1px solid rgba($color: #fff, $alpha: 0.5);
              background: rgba(244,83,31,0.5);
            }
            .more::before {
              line-height: 0;
            }
            .number{
              color: #000;
              background: #DCDCDC;
            }
            .active {
              background: red;
              color: #fff;
            }
          }
        }
    }
    .shop-hot {
      height: 700px;
      width: 300px;
      box-sizing: border-box;
      background: #fff;
      padding: 15px;
      border-radius: 5px;
      box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
      .hot-title {
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 10px;
      }
      .hot-list {
        border-bottom: 1px solid #e7eae8;
        margin-bottom: 15px;
        cursor: pointer;
        img {
          width: 270px;
          height: 150px;
          border-radius: 5px;
        }
        &:last-of-type {
          border: none;
        }
      }
    }
  }
}
</style>
