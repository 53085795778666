<template>
  <el-dialog
    title="新增收货地址"
    :visible.sync="isShow"
    width="30%"
    @close="addClose"
    ><el-form
      label-position="right"
      label-width="100px"
      :model="formData"
      class="add-address-info"
      ref="ruleForm"
      :rules="rules"
    >
      <el-form-item label="收货人姓名" prop="consigneeName">
        <el-input
          v-model="formData.consigneeName"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item label="手机号码" prop="consigneePhone">
        <el-input
          v-model="formData.consigneePhone"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item label="所在地区" prop="region">
        <el-cascader
          v-model="formData.region"
          :options="areaData"
          :props="defaultParams"
          @change="areaChange"
        ></el-cascader>
      </el-form-item>
      <el-form-item label="详细地址" prop="consigneeAddress">
        <el-input
          v-model="formData.consigneeAddress"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item label="" prop="type">
        <el-checkbox
          label="设置为默认收货地址"
          v-model="formData.isDefault"
          true-label="1"
          false-label="0"
        ></el-checkbox>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >保 存</el-button
        >
        <el-button @click="resetForm('ruleForm')">重 置</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>
<script>
import areaData from "../utils/area";
export default {
  props: {
    addAddressShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    var validateMobilePhone = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("收货人手机号不可为空"));
      } else {
        var reg = /^1[3456789]\d{9}$/;
        if (!reg.test(value)) {
          callback(new Error("请输入有效的手机号码"));
          return;
        }
        callback();
      }
    };
    return {
      isShow: false,
      areaData: areaData,
      formData: {},
      defaultParams: {
        label: "label",
        value: "label",
        children: "children",
      },
      rules: {
        consigneeName: [
          { required: true, message: "请输入收货人姓名", trigger: "blur" },
        ],
        consigneePhone: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          { validator: validateMobilePhone },
        ],
        region: [{ required: true, message: "请选择地区", trigger: "change" }],
        consigneeAddress: [
          { required: true, message: "请输入详细地址", trigger: "blur" },
        ],
      },
    };
  },
  watch: {
    // 监听 addAddressShow 改变
    addAddressShow(oldVal, newVal) {
      this.isShow = this.addAddressShow;
    },
  },
  methods: {
    // 新增收货地址
    submitForm(formName) {
      this.formData.province = this.formData.region[0];
      this.formData.city = this.formData.region[1];
      this.formData.region = this.formData.region[2];
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$axios
            .post(this.apiUrl + "/app/api/address", this.formData)
            .then((res) => {
              if (res.code == 1) {
                this.$message({
                  message: "添加成功！",
                  type: "success",
                });
                this.$emit("getShippingAddress");
                this.isShow = false;
              }
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //重置表单
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    areaChange(e) {
      console.log(e);
    },
    addClose() {
      this.$emit("changeShow", "false");
    },
  },
};
</script>
<style lang="scss" scoped>
.add-address-info {
  padding: 15px;
  background: #fff;
  border-radius: 0 0 5px 5px;
}
</style>