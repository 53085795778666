<template>
  <div class="ticket-detail">
    <p class="crumbs">
      <a href="http://huangyao.cn/index.html">首页</a> > <span>导游预约</span>
    </p>
    <div class="ticket-info">
      <!-- <p>
        <i class="el-icon-collection"></i
        >半岛酒店坐落在广西贺州市黄姚古镇景区内。秉承自然、时尚、舒适的理念，酒店设计为明清庭院式青砖黛瓦建筑布局，风格古色古香，质朴大方。典雅而不失朴素的装修风格，尽可能向各位宾客提供“家”的感觉，是各界人士休闲度假、探胜仿古，单位团体组织召开会议、开设论坛、文化交流的最佳选择。
        酒店设备设施配套适用、合理，通讯快捷，服务功能齐全，拥有豪华楼中楼复式房，标准单、豪华双人房共十四间，自助厨房和餐厅、会议室等为阁下提供尽善尽美的服务。
      </p> -->
      <Spread :mes2="mes2"></Spread>
      <div class="ticket-img">
        <div class="left">
          <div class="big-img">
            <img
              src="http://bibi-sbs.oss-cn-shanghai.aliyuncs.com/2021-11-24/1637755476547.jpg?Expires=2583835474&OSSAccessKeyId=LTAI4GJxALzftKu6WSo8n4oP&Signature=%2BDrPOWJWYoOmAIdeX0R5rlBsvM0%3D"
              alt=""
            />
            <span><i class="el-icon-picture"></i>共有1张图片</span>
          </div>
        </div>
        <div class="right">
          <div class="ticket-title">
            <span class="ticket-name">{{scenicData.scenicName}}</span>
            <div class="ticket-tags">
              <el-tag type="warning" size="mini" style="margin-right: 5px" v-for="item in scenicData.scenicTagNames" :key="item">{{item}}</el-tag>
            </div>
          </div>
          <p style="padding-bottom:10px;font-size:16px;">{{scenicData.scenicBrief}}</p>
          <p>语言：普通话、粤语、英语、日语、韩语、马来语</p>
          <p><i class="el-icon-location"></i>{{scenicData.scenicAddress}}</p>
          <p><i class="el-icon-phone"></i>电话：{{scenicData.scenicTelephone}}</p>
          <p><i class="el-icon-time"></i>营业时间：{{scenicData.openTime}}</p>
          <!-- <p>
            会议室 叫车服务 房间消毒 叫醒服务 行李奇存 前台保险柜 安全消防系统
            24小时热水
          </p> -->
          
        </div>
        <div id="ticket-map"></div>
      </div>
    </div>
    <div class="main-warp">
      <section class="main-content">
        <ul class="tab-sort" :class="{ isfiexd: isfiexd }">
          <li
            class="sort-item"
            :class="{ active: selectId == item.id }"
            v-for="item in tabData"
            :key="item.id"
            @click="goNode(item.id)"
          >
            {{ item.title }}
          </li>
        </ul>
      </section>
      <div class="unnecessary"></div>
      <div class="room-info" id="yd">
        <ul>
          <li class="room-type" v-for="item in ticketList" :key="item.ticketId">
            <div class="room-item">
              <img
                src="http://bibi-sbs.oss-cn-shanghai.aliyuncs.com/2021-11-24/1637755476547.jpg?Expires=2583835474&OSSAccessKeyId=LTAI4GJxALzftKu6WSo8n4oP&Signature=%2BDrPOWJWYoOmAIdeX0R5rlBsvM0%3D"
                alt=""
              />
              <div>
                <p>{{ item.ticketName }}</p>
                <el-popover
                  placement="bottom"
                  title="预订须知"
                  width="500"
                  trigger="click"
                  :content="item.ticketDetail">
                  <div style="white-space:pre-line;" v-html="item.ticketDetail"></div>
                  <el-button slot="reference" type="text" style="display:block;color:#e41f19;">预订须知<i class="el-icon-d-arrow-right"></i></el-button>
                </el-popover>
              </div>
            </div>
            <div class="room-item">
              <span>{{item.isReserveName?item.isReserveName:''}}</span>
              <span>{{item.isNeedBookName?item.isNeedBookName:''}}</span>
              <span>{{item.isTicketCollectionName?item.isTicketCollectionName:''}}</span>
              <span>{{item.isCanRefundName?item.isCanRefundName:''}}</span>
            </div>
            <div class="room-item">
              <span>￥{{ item.ticketPrice }}<i>起</i></span>
              <el-button @click="book(item.ticketId)"
                >预订
                <div class="code">
                  <img src="../assets/code/guide-code.png" alt="" />
                  <p>扫码预约</p>
                </div></el-button
              >
            </div>
          </li>
        </ul>
      </div>
      <div class="ticket-introduce" id="js">
        <span class="introduce-title">景区介绍</span>
        <div class="introduce-content" v-html="scenicData.scenicDetail">
          <p>
            黄姚古镇有着近千年历史，它发祥于宋朝年间，由于镇上以黄、姚两姓居多，故名“黄姚”。
          </p>
          <p>
            黄姚古镇以前不算一个知名的地方，直到《茶是故乡浓》及《酒是故乡醇》两部当年的热播剧及影片《面纱》在此取景，才让黄姚出现在聚光灯下，好在它依旧没有被过度开发。与很多江南小镇一样，黄姚也有小桥、流水、人家，有精雕细刻的檐梁壁画，有屋檐.上诗文画壁的古宅民居。石板街依旧是几百年前的老样子，青砖瓦顶的屋子连成一大片，
            显得绵延深邃。只是相对于浙江-带的古镇，黄姚要少了一份喧嚣与浮华，多了一份宁静致远的感觉，是国内值得推荐的美丽古镇。
          </p>
          <p>
            黄姚古镇内有古戏台、宝珠观、龙爪榕、带龙桥、农趣园、吴氏宗祠等景点。古戏台位于村口，整座建筑雕梁画栋，刻有各种飞檐走兽的形象，是旧时搭建的戏台，上面还有清代举人题字的“可以兴”横匾。戏台的前方就是宝珠观，观内的石柱.上雕刻着石龙，四周壁画色彩斑驳。出观门后可以看到龙爪榕，古树长在石头上，形似龙爪，令人称奇。
          </p>
          <p>
            带龙桥位于古镇的核心位置，带龙桥旁边怪石嶙峋，左边桥头下有一处酷似蛇头的石头，蛇又称小龙，可能因此才叫带龙桥吧。如果是夏天不妨去农趣园，是赏荷的好地方，园内还有复原的豆豉、豆腐、榨油古作坊。荷塘的东面就是吴氏宗祠，《茶是故乡浓》及《酒是故乡醇》的祠堂都是在在这里取的景。
          </p>
          <p>
            另外，古镇的景点还有郭家大院及兴宁庙，人文景观还有韩愈、刘宗标墨迹以及许多诗联碑刻。整个古镇如果走马观花逛-
            -圈大约2-3小时。如果想玩得更深入，可以去东门楼外天马山的文明阁。另外，摄友、发烧友还可到镇外的“市场旅社”屋顶拍摄古镇全貌(会收取5-10元左右)。或请当地人做向导，登.上附近的小山，也可俯瞰全镇。
          </p>
          <p>
            多留一点时间，在古镇里静静体会那份悠远、古朴，或与当地人坐下好好聊聊，定会有不少收获。
          </p>
          <p>
            关于用餐：古镇内及古镇外有很多餐厅，水平比较平均，黄姚豆腐是当地的一大特色菜，有豆腐和豆腐酿，两种都应该尝尝。另外，黄姚豆豉很有名，黄姚是“豆鼓之乡”，这里出产的豆鼓曾是送进京城的贡品，这里几乎每家餐馆都有“豆豉宴”。
          </p>
          <p>
            关于住宿：黄姚分为古镇和新街，新街多是以登高拍摄黄姚古镇全景为卖点的私家酒店，也适合对硬件要求较高的人群，古镇内则以文艺怀旧的客栈为主，是深度感受古镇风韵的住处。
          </p>
        </div>
      </div>
      <div class="ticket-notice" id="xz">
        <span class="notice-title">预订须知</span>
        <p class="notice-content" v-html="scenicData.scenicNotice">
          <span
            >订单确认后，请于预订日期，凭短信或订单到游客中心办理导游服。</span
          >
        </p>
      </div>
      <div class="ticket-traffic" id="fy">
        <span class="traffic-title">费用说明</span>
        <p v-html="scenicData.costDescription"></p>
      </div>
    </div>
  </div>
</template>
<script>
import Spread from "../components/spread.vue";
export default {
  components: { Spread },
  data() {
    return {
      mes2: "半岛酒店坐落在广西贺州市黄姚古镇景区内。秉承自然、时尚、舒适的理念，酒店设计为明清庭院式青砖黛瓦建筑布局，风格古色古香，质朴大方。典雅而不失朴素的装修风格，尽可能向各位宾客提供“家”的感觉，是各界人士休闲度假、探胜仿古，单位团体组织召开会议、开设论坛、文化交流的最佳选择。酒店设备设施配套适用、合理，通讯快捷，服务功能齐全，拥有豪华楼中楼复式房，标准单、豪华双人房共十四间，自助厨房和餐厅、会议室等为阁下提供尽善尽美的服务。",
      range: "1晚",
      time: "",
      ticketList: "",
      isfiexd:false,
      tabData: [
        { id: "1", title: "服务预订" },
        { id: "2", title: "景区介绍" },
        { id: "3", title: "预订须知" },
        { id: "4", title: "费用说明" },
      ],
      selectId: 1,
      scenicData:{}
    };
  },
  created() {
    //window.document.title = "导游预约";
    this.$axios
      .get(this.apiUrl + "/app/api/ticket/selectSysTicketList", {
        params: { scenicId: 2, ticketCategoryId: 2 },
      })
      .then((res) => {
        this.ticketList = res.rows;
        // console.log(this.ticketList, "ticketList");
      });
    this.getScenicData()
  },
  mounted() {
    // 此处true需要加上，不加滚动事件可能绑定不成功
    window.addEventListener("scroll", this.handleScroll, true);
    setTimeout(() => {
      this.initMap();
    }, 800);
  },
  // 离开页面销毁滚动监听事件
  destroyed(){
    window.removeEventListener("scroll", this.handleScroll,true)
  },
  methods: {
    initMap() {
      var center = new TMap.LatLng(
        Number(this.scenicData.latitude),
        Number(this.scenicData.longitude)
      );
      //初始化地图
      var map = new TMap.Map("ticket-map", {
        rotation: 20, //设置地图旋转角度
        pitch: 30, //设置俯仰角度（0~45）
        zoom: 15, //设置地图缩放级别
        center: center, //设置地图中心点坐标
      });
      var marker = new TMap.MultiMarker({
        map: map,
        styles: {
          // 点标记样式
          myStyle: new TMap.MarkerStyle({
            width: 20, // 样式宽
            height: 30, // 样式高
            color: "#F4531F",
            size: 14,
            direction: "bottom",
            anchor: { x: 10, y: 30 }, // 描点位置
            // src: "https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/markerDefault.png", // 标记路径
          }),
        },
        geometries: [
          // 点标记数据数组
          {
            styleId: "myStyle",
            // 标记位置(经度，纬度，高度)
            position: center,
            content: this.scenicData.scenicName,
          },
        ],
      });
    },
    getScenicData(){
      this.$axios.get(this.apiUrl+'/app/api/scenic/2').then(res=>{
        this.scenicData=res.data
        this.scenicData.scenicTagNames?this.scenicData.scenicTagNames=res.data.scenicTagNames.split(','):""
      })
    },
    handleScroll() {
      let scrolltop =
        document.documentElement.scrollTop || document.body.scrollTop;
      scrolltop > 470 ? (this.isfiexd = true) : (this.isfiexd = false);
      if(this.isfiexd){
        document.querySelector(".unnecessary").style.height='50px'
        document.querySelector("#js").style.paddingTop='50px'
        document.querySelector("#yd").style.paddingTop='60px'
      }else{
        document.querySelector(".unnecessary").style.height='0'
        document.querySelector("#js").style.paddingTop='28px'
        document.querySelector("#yd").style.paddingTop='0px'
      }
    },
    goNode(id) {
      this.selectId = id;
      let selectorNama;
      if (id == 2) {
        selectorNama = "#js";
      } else if (id == 3) {
        selectorNama = "#xz";
      } else if (id == 4) {
        selectorNama = "#fy";
      }
      if (id == 1) {
        document.getElementById("yd").scrollIntoView({
          behavior: "smooth", // 平滑过渡
          block: "start", // 上边框与视窗顶部平齐。默认值
        });
      } else {
        document.querySelector(selectorNama).scrollIntoView({
          behavior: "smooth", // 平滑过渡
          block: "start", // 上边框与视窗顶部平齐。默认值
        });
      }
    },
    book(id) {
      this.$router.push({
        path: "/ticketOrder",
        query: {
          ticketId: id,
        },
      });
    },
  },
};
</script>
<style scoped lang='scss'>
.ticket-detail {
  min-width: 1160px;
  width: 1000px;
  // height: 60px;
  min-height: calc(100vh - 220px);
  margin: 0 auto;
  .crumbs {
    font-size: 14px;
    font-weight: 400;
    color: rgba(29, 29, 29, 0.7);
    line-height: 30px;
    a {
      color: rgba(29, 29, 29, 0.7);
      &:hover {
        color: #c92b2f;
      }
    }
  }
  .ticket-info {
    background: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    .ticket-title{
      display: flex;
      align-items: center;
    }
    .ticket-tags{
      margin-left: 15px;
    }
    .ticket-name {
      font-size: 20px;
      font-weight: bold;
    }
    p {
      font-size: 14px;
      color: #b2b3b5;
      line-height: 30px;
      word-wrap: break-word;
      i {
        margin-right: 8px;
      }
    }
    .ticket-img {
      width: 100%;
      display: inline-flex;
      justify-content: space-between;
      .left {
        display: inline-flex;
        .big-img {
          // margin-right: 8px;
          position: relative;
          img {
            width: 352px;
            height: 270px;
            border-radius: 5px;
            display: block;
          }
          span {
            position: absolute;
            right: 15px;
            bottom: 15px;
            background: rgba(255, 255, 255, 0.9);
            border-radius: 3px;
            color: #505153;
            padding: 5px;
            display: block;
            font-size: 14px;
            line-height: 16px;
            cursor: pointer;
            .el-icon-picture {
              margin-right: 3px;
            }
          }
        }
        .small-img {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-content: space-between;
          width: 310px;
          img {
            width: 150px;
            height: 130px;
            border-radius: 5px;
          }
        }
      }
      .right {
        margin-left: 12px;
        flex: 1;
      }
      #ticket-map {
        width: 400px;
        height: 195px;
        align-self: flex-end;
      }
    }
  }
  .main-warp {
    .main-content {
      background: #fff;
      margin-bottom: 20px;
      .isfiexd {
        position: fixed;
        top: 0;
        background: #fff;
        z-index: 999;
        width: 1160px !important;
        box-shadow: 0 4px 16px 0 rgb(69 88 115 / 20%);
      }
      .tab-sort {
        width: 100%;
        display: flex;
        padding: 16px 0;
        .active {
          color: #e41f19;
          // font-weight: bold;
        }
        .sort-item {
          padding: 0 20px;
          font-size: 15px;
          cursor: pointer;
          position: relative;
          line-height: 18px;
          // &::after {
          //   content: "";
          //   width: 2px;
          //   height: 1em;
          //   background: #898989;
          //   position: absolute;
          //   top: 50%;
          //   transform: translateY(-50%);
          //   right: -1px;
          // }
        }
      }
    }
    .room-info {
      margin-bottom: 20px;
      .room-type {
        border-radius: 5px;
        padding: 20px;
        width: 100%;
        margin-bottom: 9px;
        background: #fff;
        box-sizing: border-box;
        display: inline-flex;
        justify-content: space-between;
        align-content: center;
        &:last-of-type {
          margin-bottom: 0;
          span {
            line-height: 20px !important;
            padding: 20px 0;
          }
        }
        &:first-child {
          .room-item {
            &:last-child {
              margin-left: 10px;
            }
          }
        }
        &:nth-child(2) {
          .room-item {
            &:nth-child(1) {
              span {
                padding: 20px 0;
                line-height: 20px;
              }
            }
          }
        }
        img {
          width: 120px;
          height: 80px;
          border-radius: 5px;
          margin-right: 15px;
          display: block;
        }
        .room-item {
          &:nth-child(1) {
            width: 350px;
            display: inline-flex;
            span {
              font-size: 15px;
              font-weight: bold;
              line-height: 81px;
            }
          }
          &:nth-child(2) {
            width: 400px;
            color: #6d7b7b;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          &:nth-child(3) {
            line-height: 80px;
            span {
              color: #f49a49;
              font-size: 18px;
              font-weight: bold;
              i {
                font-size: 14px;
                color: #2c3e50;
                font-weight: 500;
              }
            }
            .el-button {
              margin-left: 32px;
              color: #fff;
              background: #f3581f;
              font-size: 15px;
              position: relative;
              .code {
                width: 150px;
                height: 175px;
                position: absolute;
                right: -165px;
                top: -120px;
                display: none;
                background: #fff;
                img {
                  display: block;
                  width: 150px;
                  height: 150px;
                }
                p {
                  text-align: center;
                  color: #000;
                  line-height: 25px;
                }
              }
              &:hover {
                .code {
                  display: block;
                }
              }
            }
          }
        }
      }
    }
    .ticket-introduce {
      // height: 209px;
      background: #fff;
      border-radius: 5px;
      margin-bottom: 20px;
      padding: 28px 30px;
      .introduce-title {
        font-size: 20px;
        font-weight: bold;
        padding-left: 10px;
        position: relative;
        display: block;
        margin-bottom: 15px;
        &::before {
          content: "";
          width: 6px;
          height: 18px;
          background: #f3581f;
          border-radius: 5px;
          position: absolute;
          left: -6px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      .introduce-content {
        // font-size: 14px;
        color: #6d7b7b;
        line-height: 28px;
      }
    }
    .ticket-notice {
      // height: 120px;
      background: #fff;
      border-radius: 5px;
      margin-bottom: 20px;
      padding: 28px 30px;
      .notice-title {
        font-size: 20px;
        font-weight: bold;
        padding-left: 10px;
        position: relative;
        display: block;
        margin-bottom: 12px;
        &::before {
          content: "";
          width: 6px;
          height: 18px;
          background: #f3581f;
          border-radius: 5px;
          position: absolute;
          left: -6px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      .notice-content {
        color: #6d7b7b;
        span {
          display: block;
          &:nth-child(2) {
            padding: 7px 0;
          }
        }
      }
    }
    .ticket-traffic {
      // height: 176px;
      background: #fff;
      border-radius: 5px;
      padding: 28px 30px;
      .traffic-title {
        font-size: 20px;
        font-weight: bold;
        padding-left: 10px;
        display: block;
        margin-bottom: 12px;
        position: relative;
        &::before {
          content: "";
          width: 6px;
          height: 18px;
          background: #f3581f;
          border-radius: 5px;
          position: absolute;
          left: -6px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      p {
        color: #6d7b7b;
      }
    }
  }
}
</style>
