<template>
  <div class="ticket-content">
    <p class="crumbs"><a href="http://huangyao.cn/index.html">首页</a> > <span>门票预订</span></p>
    <section class="main-content">
      <ul class="tab-sort">
        <li class="sort-item active">智能排序</li>
        <li class="sort-item">好评优先</li>
        <li class="sort-item">人气优先</li>
        <li class="sort-item">价格最低</li>
        <li class="sort-item">价格最高</li>
      </ul>
    </section>
    <div class="ticket-main">
      <div class="ticket-list">
        <ul class="list-box">
          <li class="ticket-item">
            <div class="left">
              <div class="ticket-img">
                <a @click="go">
                  <img src="http://bibi-sbs.oss-cn-shanghai.aliyuncs.com/2021-11-24/1637755681944.jpg?Expires=2583835678&OSSAccessKeyId=LTAI4GJxALzftKu6WSo8n4oP&Signature=iPUeIydevj6QU%2FfR%2Fq8GDP%2Bz3%2Bs%3D" alt="" />
                </a>
              </div>
              <div class="ticket-msg">
                <span class="ticket-name"
                  ><a @click="go">黄姚古镇景区门票 </a></span
                >
                <el-tag type="success" size="mini" style="margin-right: 5px"
                  >即买即入</el-tag
                >
                <el-tag type="warning" size="mini">不可退</el-tag>
                <p>成人票、半价票、特价票</p>
              </div>
            </div>
            <div class="right">
              <div class="ticket-info">
                <p><span>￥20</span>起</p>
                <button @click="go">立即预订<div class="code">
                  <img src="../assets/code/ticket-code.png" alt="" />
                  <p>扫码预订</p>
                </div></button>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <!-- <div id="ticket-map"></div> -->
    </div>
  </div>
</template>
<script>
// @ is an alias to /src

export default {
  name: "Hotel",
  data() {
    return {
      ticketList:''
    };
  },
  created(){
    //window.document.title='门票预订'
    this.$axios.get(this.apiUrl+'/app/api/ticket/selectSysTicketList',{params:{scenicId:1,ticketCategoryId:1}})
        .then((res) => {
          this.ticketList =res.rows
          // console.log(this.ticketList,'ticketList')
        });
  },
  mounted() {
  },
  methods: {
    go() {
      this.$router.push({ path: "/ticketDetail" });
    },
    
  },
};
</script>
<style scoped lang='scss'>
.ticket-content {
  min-width: 1160px;
  width: 1000px;
  // height: 60px;
  min-height: calc(100vh - 220px);
  margin: 0 auto;
  .crumbs{
      font-size: 14px;
      font-weight: 400;
      color: rgba(29, 29, 29, 0.7);
      line-height: 30px;
    a{
      color: rgba(29, 29, 29, 0.7);
      &:hover{
        color: #C92B2F;
      }
    }
  }
  .main-content {
    background: #fff;
    .tab-sort {
      width: 100%;
      display: flex;
      padding: 16px 0;
      .sort-item {
        padding: 0 20px;
        font-size: 15px;
        line-height: 18px;
        cursor: pointer;
        position: relative;
        &::after {
          content: "";
          width: 2px;
          height: 1em;
          background: #898989;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: -1px;
        }
      }
    }
  }
  .ticket-main {
    display: inline-flex;
    padding-top: 20px;
    .ticket-list {
      .list-box {
        padding-right: 20px;
        .ticket-item {
          width: 750px;
          height: 150px;
          background: #fff;
          box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
          border-radius: 5px;
          padding: 20px;
          margin-bottom: 20px;
          display: inline-flex;
          justify-content: space-between;
          .left {
            display: inline-flex;
            padding-right: 10px;
            border-right: 0.5px solid #f5f5f5;
            width: 530px;
            .ticket-img {
              width: 180px;
              height: 150px;
              margin-right: 15px;
              img {
                width: 180px;
                height: 150px;
                border-radius: 3px;
              }
            }
            .ticket-msg {
              .ticket-name {
                display: block;
                font-weight: bold;
                font-size: 20px;
                margin-bottom: 8px;
                cursor: pointer;
                a {
                  color: #2c3150;
                  &:hover {
                    color: #f3581f;
                    font-weight: bold;
                  }
                }
              }
              p {
                font-size: 14px;
                color: #b2b3b5;
                margin: 8px 0;
              }
            }
          }
          .right {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            .ticket-info {
              p {
                font-size: 14px;
                text-align: right;
                span {
                  font-size: 20px;
                  font-weight: bold;
                  color: #f49a49;
                }
              }
              button {
                width: 100px;
                height: 35px;
                color: #fff;
                font-size: 16px;
                line-height: 30px;
                background: #f4531f;
                border-radius: 5px;
                border: none;
                cursor: pointer;
                position: relative;
                .code {
                  width: 150px;
                  height: 175px;
                  position: absolute;
                  right: -150px;
                  top: -120px;
                  display: none;
                  background: #fff;
                  img{
                    display: block;
                    width: 150px;
                    height: 150px;
                  }
                  p{
                    text-align: center;
                    color: #000;
                    line-height: 25px;
                  }
                }
                &:hover {
                  .code {
                    display: block;
                  }
                }
              }
            }
          }
        }
      }
    }
    #ticket-map {
      height: 350px;
      width: 100%;
    }
  }
}
</style>
