<template>
  <div class="page-content">
    <el-breadcrumb separator=">" class="crumb">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>我的购物车</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="shop-cart">
      <h2>购物车（全部 {{ tableData.length }}）</h2>
      <el-table
        ref="shopTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="shopSelectionChange"
        :header-cell-style="{ 'text-align': 'center', padding: '5px' }"
        :cell-style="{ 'text-align': 'center', 'font-size': '15px' }"
      >
        <el-table-column :selectable="checkSelect" type="selection" width="55">
        </el-table-column>
        <el-table-column label="商品信息" width="250">
          <div slot-scope="scope" style="display: flex">
            <img
              :src="imgUrl + scope.row.coverImage"
              alt=""
              style="width: 100px; height: 100px; margin-right: 8px"
            />
            <p>
              {{ scope.row.merchandiseName }}
              <span style="display: block; font-size: 13px; color: #8e908e">{{
                scope.row.sku
              }}</span>
            </p>
          </div>
        </el-table-column>
        <el-table-column prop="salePrice" label="单价（￥）" width="120">
        </el-table-column>
        <el-table-column prop="num" label="数量" width="150">
          <template slot-scope="scope">
            <el-input-number
              v-model="scope.row.num"
              @change="
                numChange(
                  scope.row.num,
                  scope.row.userCartId,
                  scope.row.inventory
                )
              "
              style="width: 100px"
              size="mini"
              :min="1"
              :max="scope.row.inventory"
            ></el-input-number>
          </template>
        </el-table-column>
        <el-table-column label="金额（￥）">
          <template slot-scope="scope">
            {{ Math.floor(scope.row.salePrice * scope.row.num * 100) / 100 }}
          </template>
        </el-table-column>
        <el-table-column label="邮寄方式" prop="postageTypeName">
          <!-- <template slot-scope="scope">
            
          </template> -->
        </el-table-column>
        <el-table-column label="商品状态">
          <template slot-scope="scope">
            <el-tag
              :type="scope.row.isOnSale == '1' ? 'success' : 'danger'"
              size="small"
            >
              {{ scope.row.isOnSale == "1" ? "正 常" : "已下架" }}</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="shopDelete(scope.row)"
              >移 除</el-button
            >
          </template>
        </el-table-column>
        <template slot="empty">
          <p>
            您的购物车空空如也，<router-link to="/shop" style="color: red"
              >去逛逛</router-link
            >
          </p>
        </template>
      </el-table>
      <div class="shop-total">
        <div style="margin-left: 35px">
          <el-button type="danger" @click="selectAll(tableData)"
            >全 选</el-button
          >
          <el-button
            type="danger"
            class="delete-goods"
            :disabled="goodsSelection.length == 0"
            @click="batchDelete"
            >移 除</el-button
          >
        </div>

        <div>
          已选商品（不含运费）：<span>￥{{ shopTotal }}</span>
          <el-button
            type="danger"
            :disabled="goodsSelection.length == 0"
            @click="placeOrder"
            >结 算</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tableData: [],
      goodsSelection: [],
      shopTotal: 0,
      cartIds: [],
    };
  },
  created() {
    //window.document.title = "我的购物车";
    this.getShopCart();
  },
  methods: {
    //获取购物车数据
    getShopCart() {
      this.$axios
        .get(this.apiUrl + "/app/api/cart/selectSysUserCartList", {
          params: { buyType: 0 },
        })
        .then((res) => {
          if (res.code == 1) {
            this.tableData = res.rows;
          }
        });
    },
    //勾选事件
    shopSelectionChange(val) {
      if (this.goodsSelection.length !== 0) {
        this.shopTotal = 0;
      }
      this.goodsSelection = val;
      // console.log(this.goodsSelection, "goodsSelection");
      this.cartIds = [];
      for (let i = 0; i < this.goodsSelection.length; i++) {
        this.shopTotal +=
          this.goodsSelection[i].num * this.goodsSelection[i].salePrice;
        this.cartIds.push(this.goodsSelection[i].userCartId);
      }
      this.shopTotal = Math.floor(this.shopTotal * 100) / 100;
    },
    checkSelect(row, index) {
      let isChecked = true;
      if (row.isOnSale === "1") {
        // 判断里面是否存在某个参数
        isChecked = true;
      } else {
        isChecked = false;
      }
      return isChecked;
    },
    //更改某个商品数量
    numChange(number, id, inventory) {
      if (number == inventory) {
        this.$notify({
          title: "警告",
          message: "已达该商品最大数量！",
          type: "warning",
        });
      }
      this.shopSelectionChange(this.goodsSelection);
      let numChange = {
        userCartId: id,
        num: number,
      };
      this.$axios
        .put(this.apiUrl + "/app/api/cart", numChange)
        .then((res) => {});
    },
    shopDelete(row) {
      //   console.log(row, "row");
      let deteleCartId = [];
      if (row.constructor === Array) {
        for (let i = 0; i < row.length; i++) {
          deteleCartId.push(row[i].userCartId);
        }
        // console.log(deteleCartId, "批量移除的userCartId集合");
      } else {
        deteleCartId.push(row.userCartId);
      }
      this.$axios
        .delete(this.apiUrl + "/app/api/cart/" + deteleCartId.toString())
        .then((res) => {
          if (res.code == 1) {
            this.$message({
              message: "移除成功！",
              type: "success",
            });
            this.getShopCart();
          }
        });
    },
    //全选
    selectAll(tableData) {
      tableData
        ? this.$refs.shopTable.toggleAllSelection()
        : this.$refs.shopTable.clearSelection();
    },
    // 批量删除
    batchDelete() {
      // console.log(this.goodsSelection,'勾选的数据')
      this.shopDelete(this.goodsSelection);
    },
    //跳转提交订单页
    placeOrder() {
      if (!this.isSamePostage(this.cartIds)) {
        this.$message({
          message: "有存在特定邮寄方式的商品，请分别下单",
          type: "warning",
        });
        return;
      }
      this.$router.push({
        path: "/ticketOrder",
        query: {
          cartIds: this.cartIds.toString(),
        },
      });
    },
    isSamePostage(cartIds) {
      //是否存在只可自提商品
      let isExistPostage = false;
      //是否存在只可邮寄商品
      let isExistPickUp = false;
      this.goodsSelection.map((item) => {
        cartIds.map((item1) => {
          if (item.userCartId == item1) {
            if (item.postageType == "2") {
              isExistPostage = true;
            }
            if (item.postageType == "3") {
              isExistPickUp = true;
            }
          }
        });
      });
      if (isExistPostage && isExistPickUp) {
        return false;
      }
      return true;
    },
  },
};
</script>
<style lang="scss" scoped>
.page-content {
  min-width: 1160px;
  width: 1000px;
  min-height: calc(100vh - 220px);
  margin: 0 auto;
  .crumb {
    line-height: 30px;
    ::v-deep .el-breadcrumb__item {
      color: rgba(29, 29, 29, 0.7);
      .el-breadcrumb__separator {
        font-weight: 500;
      }
    }
  }
  .shop-cart {
    background: #fff;
    padding: 15px;
    h2 {
      padding: 0 15px;
      font-weight: bold;
    }
  }
  .shop-total {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    padding-top: 15px;
    .delete-goods {
      margin-left: 15px;
    }
    span {
      color: red;
      font-size: 18px;
      font-weight: bold;
      margin-right: 10px;
    }
  }
}
</style>